import * as TokenType from './tokenType'
import xplct_idl from './xplct.json'
import { Buffer } from 'buffer'
import { clusterApiUrl } from '@solana/web3.js'
import * as anchor from '@project-serum/anchor'
import {
  Program,
  AnchorProvider,
  web3,
  BorshCoder,
} from '@project-serum/anchor'
import {
  getAssociatedTokenAddress,
  TOKEN_PROGRAM_ID,
  createAssociatedTokenAccountInstruction,
} from '@solana/spl-token'

import {
  useWallet,
  ConnectionProvider,
  WalletProvider,
  WalletContext,
} from '@solana/wallet-adapter-react'

import {
  WalletModalProvider,
  WalletMultiButton,
} from '@solana/wallet-adapter-react-ui'

import { WalletContextState } from '@solana/wallet-adapter-react'

import { WalletAdapterNetwork } from '@solana/wallet-adapter-base'
import {
  PhantomWalletAdapter,
  PhantomWalletName,
} from '@solana/wallet-adapter-phantom'

import { utf8 } from '@project-serum/anchor/dist/cjs/utils/bytes'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { BorshTypesCoder } from '@project-serum/anchor/dist/cjs/coder/borsh/types'
require('@solana/wallet-adapter-react-ui/styles.css')
const network = 'https://api.devnet.solana.com'
const connection = new anchor.web3.Connection(network, 'confirmed')

export const Buy = (props) => {
  return (
    <Context
      tokenAmount={props.tokenAmount}
      tokenType={props.tokenType}
    />
  )
  // return <Content tokenAmount={props.tokenAmount} tokenType={props.tokenType} />
}
export default Buy

const Context = (props) => {
  const network = WalletAdapterNetwork.Devnet
  const endpoint = useMemo(() => clusterApiUrl(network), [network])
  const wallets = useMemo(() => [new PhantomWalletAdapter()], [network]) //? May be here we can add other compitable wallet too
  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets}>
        <WalletModalProvider>
          <Content
            tokenAmount={props.tokenAmount}
            tokenType={props.tokenType}
          />
        </WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  )
}

const Content = (props) => {
  console.log('Wallet set ...')

  window.Buffer = Buffer
  let wallet = useWallet()

  function _getprovider() {
    if (!wallet) {
      return null
    }
    const tmp = new AnchorProvider(connection, wallet, {
      preflightCommitment: 'confirmed',
    })
    return tmp
  }

  let requestTime = null
  let provider = _getprovider()
  const a = JSON.stringify(xplct_idl)
  const b = JSON.parse(a)
  const program_id = new web3.PublicKey(xplct_idl.metadata.address.toString())
  let program = new Program(b, program_id, provider)
  let token_id = new anchor.web3.PublicKey(
    'd55cgyF3QV32UWHUp3j2yvyDUz5ywg713C1Q6Adxmhj',
  )

  //? This value is set by the setIds()
  let ray_id = null
  let srm_id = null
  let usdc_id = null
  let r_ray_ata = null
  let r_srm_ata = null
  let r_usdc_ata = null
  let r_sol = null

  //? this value is set by theh getAndSetAta()
  let programAta = null
  let programAccount = null
  let payerAta = null
  let payerRayAta = null
  let payerSrmAta = null
  let payerUsdcAta = null
  let payerEntryAccount = null

  const seed1 = token_id.toBytes()
  // const seed2 = utf8.encode('ok9') //? need to change as main deployed  program seed.
  const seed2 = utf8.encode('ok1') //? need to change as main deployed  program seed.
  const seedVesting = utf8.encode('g2')

  async function sendTransaction(tx) {
    let res = null
    try {
      // tx.recentBlockhash = (await connection.getRecentBlockhash()).blockhash
      tx.recentBlockhash = (await connection.getLatestBlockhash()).blockhash
      tx.feePayer = window.solana.publicKey

      res = await window.solana.signAndSendTransaction(tx)

      let signature = res?.signature
      console.log('Signature : ', signature)
      alert('Transaction Successful ', signature)
      console.log('PayerEntryKey : ', payerEntryAccount.toBase58())
      //? NEED TO STORE THIS Signature and payerEntryAccount in DB.
    } catch (e) {
      alert('Transaction Fails')
    }
  }

  async function getPayerEntryAccount(requestTime) {
    let [k, _] = await anchor.web3.PublicKey.findProgramAddress(
      [token_id.toBytes(), seedVesting, utf8.encode(requestTime.toString())],
      program_id,
    )

    return k
  }

  const setIds = async () => {
    await getAndSetAta()
    const data = await program.account.pAccount.fetch(programAccount)
    if (data === null) {
      console.log('Failed to get data may be wrong account you have passed')
      return null
    }

    token_id = new anchor.web3.PublicKey(data.tokenId.toBytes())
    ray_id = new anchor.web3.PublicKey(data.rayId.toBytes())
    srm_id = new anchor.web3.PublicKey(data.srmId.toBytes())
    usdc_id = new anchor.web3.PublicKey(data.usdcId.toBytes())
    r_ray_ata = new anchor.web3.PublicKey(data.rayAta.toBytes())
    r_srm_ata = new anchor.web3.PublicKey(data.srmAta.toBytes())
    r_usdc_ata = new anchor.web3.PublicKey(data.usdcAta.toBytes())
    r_sol = new anchor.web3.PublicKey(data.owner.toBytes())
  }

  async function getAndSetAta(tt) {
    let payer = window?.solana.publicKey
    let [programAccountPda, _] = await anchor.web3.PublicKey.findProgramAddress(
      [seed1, seed2],
      program_id,
    )

    programAta = await getAssociatedTokenAddress(
      token_id,
      programAccountPda,
      true,
    )
    programAccount = programAccountPda

    if (tt === TokenType.RAY) {
      payerRayAta = await getAssociatedTokenAddress(ray_id, payer)
      let res = await connection.getAccountInfo(payerRayAta)
      if (res === null) {
        payerRayAta = null
        alert('Sorry! We are unable to find your RAY token account')
        return null
      }
    } else if (tt === TokenType.SRM) {
      payerSrmAta = await getAssociatedTokenAddress(srm_id, payer)
      let res = await connection.getAccountInfo(payerSrmAta)
      if (res === null) {
        payerSrmAta = null
        alert('Sorry! We are unable to find your SRM token account')
        return null
      }
    } else if (tt === TokenType.USDC) {
      payerUsdcAta = await getAssociatedTokenAddress(usdc_id, payer)
      let res = await connection.getAccountInfo(payerUsdcAta)
      if (res === null) {
        payerUsdcAta = null
        alert('Sorry! We are unable to find your USDC token account')
        return null
      }
    }

    payerAta = await getAssociatedTokenAddress(
      token_id,
      window.solana.publicKey,
    )
  }

  function parseTokenAmount(val, decimal) {
    return Math.trunc(Math.pow(10, decimal) * val)
  }

  const buyFromSol = async (tokenAmount) => {
    const result = await getAndSetAta(TokenType.SOL)
    if (result === null) {
      alert('Oops! Something problem ')
      return null
    }
    let tx = new anchor.web3.Transaction()
    let result2 = await connection.getAccountInfo(payerAta)
    if (result2 === null) {
      let createAtaTX = createAssociatedTokenAccountInstruction(
        window.solana.publicKey,
        payerAta,
        window.solana.publicKey,
        token_id,
      )
      tx.add(createAtaTX)
    }
    let amount = parseTokenAmount(tokenAmount, 6)

    let buyTx = await program.methods
      .buyTokenFromSol(new anchor.BN(amount), requestTime)
      .accounts({
        tokenProgram: TOKEN_PROGRAM_ID,
        systemProgram: anchor.web3.SystemProgram.programId,
        buyer: window.solana.publicKey,
        buyerAta: payerAta,
        buyerEntryAccount: payerEntryAccount,
        owner: r_sol,
        tokenId: token_id,
        programAccount: programAccount,
        programAta: programAta,
      })
      .transaction()
    tx.add(buyTx)

    //? Refund testing
    // const buyTx = await program.methods.refundToken(requestTime).accounts({
    //   buyer: window.solana.publicKey,
    //   buyerAta: payerAta,
    //   buyerEntryAccount: payerEntryAccount,
    //   programAccount: programAccount,
    //   programAta: programAta,
    //   tokenProgram: TOKEN_PROGRAM_ID,
    // }).transaction();

    await sendTransaction(tx)
    console.log(`${tokenAmount} token bought from the sol`)
  }

  const buyFromRay = async (tokenAmount) => {
    const result = await getAndSetAta(TokenType.RAY)
    if (result === null) {
      return null
    }
    let tx = new anchor.web3.Transaction()
    let result2 = await connection.getAccountInfo(payerAta)
    if (result2 === null) {
      let createAtaTX = createAssociatedTokenAccountInstruction(
        window.solana.publicKey,
        payerAta,
        window.solana.publicKey,
        token_id,
      )
      tx.add(createAtaTX)
    }
    let amount = parseTokenAmount(tokenAmount, 6)

    const buyTx = await program.methods
      .buyTokenFromRay(new anchor.BN(amount), requestTime)
      .accounts({
        buyer: window?.solana?.publicKey,
        buyerAta: payerAta,
        buyerEntryAccount: payerEntryAccount,
        programAta: programAta,
        receiveTokenId: token_id,
        senderAta: payerRayAta,
        receiverAta: r_ray_ata,
        sendTokenId: ray_id,
        programAccount: programAccount,
        tokenProgram: TOKEN_PROGRAM_ID,
        systemProgram: anchor.web3.SystemProgram.programId,
      })
      .transaction()
    tx.add(buyTx)
    await sendTransaction(tx)
    console.log(`${tokenAmount} token bought from the Ray`)
  }

  const buyFromSrm = async (tokenAmount) => {
    const result = await getAndSetAta(TokenType.SRM)
    if (result === null) {
      return null
    }
    let tx = new anchor.web3.Transaction()
    let result2 = await connection.getAccountInfo(payerAta)
    if (result2 === null) {
      let createAtaTX = createAssociatedTokenAccountInstruction(
        window.solana.publicKey,
        payerAta,
        window.solana.publicKey,
        token_id,
      )
      tx.add(createAtaTX)
    }
    let amount = parseTokenAmount(tokenAmount, 6)

    const buyTx = await program.methods
      .buyTokenFromSrm(new anchor.BN(amount), requestTime)
      .accounts({
        buyer: window?.solana?.publicKey,
        buyerAta: payerAta,
        buyerEntryAccount: payerEntryAccount,
        programAta: programAta,
        receiveTokenId: token_id,
        senderAta: payerSrmAta,
        receiverAta: r_srm_ata,
        sendTokenId: srm_id,
        programAccount: programAccount,
        tokenProgram: TOKEN_PROGRAM_ID,
        systemProgram: anchor.web3.SystemProgram.programId,
      })
      .transaction()
    tx.add(buyTx)
    await sendTransaction(tx)
    console.log(`${tokenAmount} token bought from the SRM`)
  }

  const buyFromUsdc = async (tokenAmount) => {
    const result = await getAndSetAta(TokenType.USDC)
    if (result === null) {
      return null
    }
    let tx = new anchor.web3.Transaction()
    let result2 = await connection.getAccountInfo(payerAta)
    if (result2 === null) {
      let createAtaTX = createAssociatedTokenAccountInstruction(
        window.solana.publicKey,
        payerAta,
        window.solana.publicKey,
        token_id,
      )
      tx.add(createAtaTX)
    }
    let amount = parseTokenAmount(tokenAmount, 6) //! set with actual deciaml of buy token.

    const buyTx = await program.methods
      .buyTokenFromUsdc(new anchor.BN(amount), requestTime)
      .accounts({
        buyer: window?.solana.publicKey,
        buyerAta: payerAta,
        buyerEntryAccount: payerEntryAccount,
        programAta: programAta,
        receiveTokenId: token_id,
        senderAta: payerUsdcAta,
        receiverAta: r_usdc_ata,
        sendTokenId: usdc_id,
        programAccount: programAccount,
        tokenProgram: TOKEN_PROGRAM_ID,
        systemProgram: anchor.web3.SystemProgram.programId,
      })
      .transaction()
    tx.add(buyTx)
    await sendTransaction(tx)
    console.log(`${tokenAmount} token bought from the USDC`)
  }

  const buy = async (tokenType, tokenAmount) => {
    let status = window?.solana?.isConnected
    if (status !== true) {
      alert('Please connect with Phantom Wallet')
      return null
    }

    if (tokenAmount <= 0) {
      alert('Please Insert valid token Amount ( x > 0 )')
      return null
    }

    if (ray_id == null) await setIds()

    const date = new Date()
    requestTime = Math.trunc(date.getTime() / 1000).toString()
    
    if (requestTime == null) {
      alert("Something Wrong !")
      return null
    } //? DON'T KNOW REQURE OR NOT!

    payerEntryAccount = await getPayerEntryAccount(requestTime)

    if (tokenType === TokenType.SOL) await buyFromSol(tokenAmount)
    else if (tokenType === TokenType.RAY) await buyFromRay(tokenAmount)
    else if (tokenType === TokenType.SRM) await buyFromSrm(tokenAmount)
    else if (tokenType === TokenType.USDC) await buyFromUsdc(tokenAmount)
    else {
      alert('Wrong input !')
      return null
    }
  }

  
  const buy2 = async (tokenType, tokenAmount)=>{
    if (ray_id == null) await setIds()

    const date = new Date()
    requestTime = Math.trunc(date.getTime() / 1000).toString()
    payerEntryAccount = await getPayerEntryAccount(requestTime)
    if (tokenType === TokenType.SOL) await buyFromSol(tokenAmount)

  }

const [isLoginUser,setIsLoginUser] =useState("");

  useState(()=>{
    setIsLoginUser(localStorage.getItem('isUserLogin'));
  },[])

  return (
    <div className="App">
      <button
        disabled={window.atob(isLoginUser)==="true"?"":true}
        class="btn btn-primary payment-btn tokenbtn"
        onClick={async () => await buy(props.tokenType, props.tokenAmount)}
      >
        Purchase Tokens
      </button>
    </div>
  )
}
