import React, { useState } from 'react'
import '../Dashboard/Dashboard.css'
import '../../admin-assets/css/style.css'
import '../../admin-assets/css/vendor.bundle.css'
// import '../../admin-assets/js/script'
import logo from '../../images/home/03a.png'
import userthumbsm from '../../admin-assets/images/user-thumb-sm.png'
import userthumblg from '../../admin-assets/images/user-thumb-lg.png'
import interest from '../../admin-assets/images/vector-a.png'
import $ from 'jquery'
import RangeSlider from 'react-bootstrap-range-slider';
import TopBar from '../TopBar'
import Sidebar from '../Sidebar'

import {Lister} from '../../utils/CreateList'

// import {Lister} from './TxLister'

const Transactions = () => {
    $(document).ready(function () {
        $('.toggle-nav').click(function () {
            $('.open-mobile-sidebaar').toggleClass('active');
        })
    })
    
    const [value, setValue] = useState(0)

    return (
        <div className='user-dashboard'>
            {/* <TopBar /> */}
            {/* <div className='user-wraper'>
                <div class="container">
                    <div class="d-flex">
                        <Sidebar /> */}
                        <div className='user-content'>
                            <div class="user-panel">
                                <h2 class="user-panel-title transaction-title">Transactions</h2>
                                <div id="DataTables_Table_0_wrapper" class="dataTables_wrapper dataTables_wrapper2 dt-bootstrap5 no-footer">
                                    <div class="row"><div class="col-10 text-start"><div id="DataTables_Table_0_filter" class="dataTables_filter dataTables_filter2"><label>
                                        <input type="search" class="form-control custom-input form-control-sm" placeholder="Type in to Search" aria-controls="DataTables_Table_0"></input>
                                    </label></div></div><div class="col-2 text-end"><div class="data-table-filter data-table-filter2 dropdown"><a href="#" data-bs-toggle="dropdown"><em class="ti ti-settings"></em></a><ul class="dropdown-menu dropdown-menu-end"><li><input class="data-filter data-filter-approved" type="radio" name="filter" id="all" checked="" value=""></input><label for="all">All</label></li><li><input class="data-filter data-filter-approved" type="radio" name="filter" id="approved" value="approved"></input><label for="approved">Approved</label></li><li><input class="data-filter data-filter-pending" type="radio" name="filter" value="pending" id="pending"></input><label for="pending">Pending</label></li><li><input class="data-filter data-filter-cancled" type="radio" name="filter" value="cancled" id="cancled"></input><label for="cancled">Cancled</label></li></ul></div></div></div><div class="row"><div class="col-12"><div class="overflow-x-auto"><table class="data-table tranx-table dataTable no-footer" id="DataTables_Table_0" aria-describedby="DataTables_Table_0_info">
                                        <thead>
                                            {/* <tr><th class="tranx-status sorting_disabled" rowspan="1" colspan="1"></th><th class="tranx-no sorting_disabled" rowspan="1" colspan="1"><span>TNX NO</span></th><th class="tranx-token sorting_disabled" rowspan="1" colspan="1"><span>Tokens</span></th><th class="tranx-amount sorting_disabled" rowspan="1" colspan="1"><span>Amount</span></th><th class="tranx-from sorting_disabled" rowspan="1" colspan="1"><span>From</span></th><th class="tranx-action sorting_disabled" rowspan="1" colspan="1"></th></tr> */}
                                            <tr><th class="tranx-status sorting_disabled" rowspan="1" colspan="1"></th><th class="tranx-no sorting_disabled" rowspan="1" colspan="1"><span>TNX NO</span> </th><th><span></span> </th><th class="tranx-amount sorting_disabled" rowspan="1" colspan="1"><span>Tokens</span></th><th class="tranx-from sorting_disabled" rowspan="1" colspan="1"><span>Cost</span></th></tr>
                                        </thead>
                                        <tbody id="transactionList">
                                            {/* <Lister /> */}
                                            {/* <tr class="odd">
                                                <td class="tranx-status tranx-status-approved"><span class="d-none">Approved</span><em class="ti ti-check"></em></td>
                                                <td class="tranx-no"><span>ICIYOW0102</span>08 Jul, 18 10:20PM</td>
                                                <td class="tranx-token"><span>+5,600</span>ICOX</td>
                                                <td class="tranx-amount"><span>56.00</span>ETH <em class="fas fa-info-circle" data-bs-toggle="tooltip" data-placement="bottom" title="" data-bs-original-title="1 ETH = 100 ICOX" aria-label="1 ETH = 100 ICOX"></em></td>
                                                <td class="tranx-from"><span>1F1t....4xqX</span>08 Jul, 18 10:20PM</td>
                                                <td class="tranx-action">
                                                    <a href="#" data-bs-toggle="modal" data-bs-target="#tranxApproved"><em class="ti ti-more-alt"></em></a>
                                                </td>
                                            </tr><tr class="even">
                                                <td class="tranx-status tranx-status-pending"><span class="d-none">Pending</span><em class="ti ti-alert"></em></td>
                                                <td class="tranx-no"><span>ICIYOW0102</span>08 Jul, 18 10:20PM</td>
                                                <td class="tranx-token"><span>+5,600</span>ICOX</td>
                                                <td class="tranx-amount"><span>56.00</span>ETH <em class="fas fa-info-circle" data-bs-toggle="tooltip" data-placement="bottom" title="" data-bs-original-title="1 ETH = 100 ICOX" aria-label="1 ETH = 100 ICOX"></em></td>
                                                <td class="tranx-from"><span>1F1t....4xqX</span>08 Jul, 18 10:20PM</td>
                                                <td class="tranx-action">
                                                    <a href="#" data-bs-toggle="modal" data-bs-target="#tranxApproved"><em class="ti ti-more-alt"></em></a>
                                                </td>
                                            </tr><tr class="odd">
                                                <td class="tranx-status tranx-status-cancled"><span class="d-none">Canceled</span><em class="ti ti-close"></em></td>
                                                <td class="tranx-no"><span>ICIYOW0102</span>08 Jul, 18 10:20PM</td>
                                                <td class="tranx-token"><span>+5,600</span>ICOX</td>
                                                <td class="tranx-amount"><span>56.00</span>ETH <em class="fas fa-info-circle" data-bs-toggle="tooltip" data-placement="bottom" title="" data-bs-original-title="1 ETH = 100 ICOX" aria-label="1 ETH = 100 ICOX"></em></td>
                                                <td class="tranx-from"><span>1F1t....4xqX</span>08 Jul, 18 10:20PM</td>
                                                <td class="tranx-action">
                                                    <a href="#" data-bs-toggle="modal" data-bs-target="#tranxApproved"><em class="ti ti-more-alt"></em></a>
                                                </td>
                                            </tr><tr class="even">
                                                <td class="tranx-status tranx-status-approved"><span class="d-none">Approved</span><em class="ti ti-check"></em></td>
                                                <td class="tranx-no"><span>ICIYOW0102</span>08 Jul, 18 10:20PM</td>
                                                <td class="tranx-token"><span>+5,600</span>ICOX</td>
                                                <td class="tranx-amount"><span>56.00</span>ETH <em class="fas fa-info-circle" data-bs-toggle="tooltip" data-placement="bottom" title="" data-bs-original-title="1 ETH = 100 ICOX" aria-label="1 ETH = 100 ICOX"></em></td>
                                                <td class="tranx-from"><span>1F1t....4xqX</span>08 Jul, 18 10:20PM</td>
                                                <td class="tranx-action">
                                                    <a href="#" data-bs-toggle="modal" data-bs-target="#tranxApproved"><em class="ti ti-more-alt"></em></a>
                                                </td>
                                            </tr><tr class="odd">
                                                <td class="tranx-status tranx-status-approved"><span class="d-none">Approved</span><em class="ti ti-check"></em></td>
                                                <td class="tranx-no"><span>ICIYOW0102</span>08 Jul, 18 10:20PM</td>
                                                <td class="tranx-token"><span>+5,600</span>ICOX</td>
                                                <td class="tranx-amount"><span>56.00</span>ETH <em class="fas fa-info-circle" data-bs-toggle="tooltip" data-placement="bottom" title="" data-bs-original-title="1 ETH = 100 ICOX" aria-label="1 ETH = 100 ICOX"></em></td>
                                                <td class="tranx-from"><span>1F1t....4xqX</span>08 Jul, 18 10:20PM</td>
                                                <td class="tranx-action">
                                                    <a href="#" data-bs-toggle="modal" data-bs-target="#tranxApproved"><em class="ti ti-more-alt"></em></a>
                                                </td>
                                            </tr><tr class="even">
                                                <td class="tranx-status tranx-status-approved"><span class="d-none">Approved</span><em class="ti ti-check"></em></td>
                                                <td class="tranx-no"><span>ICIYOW0102</span>08 Jul, 18 10:20PM</td>
                                                <td class="tranx-token"><span>+5,600</span>ICOX</td>
                                                <td class="tranx-amount"><span>56.00</span>ETH <em class="fas fa-info-circle" data-bs-toggle="tooltip" data-placement="bottom" title="" data-bs-original-title="1 ETH = 100 ICOX" aria-label="1 ETH = 100 ICOX"></em></td>
                                                <td class="tranx-from"><span>1F1t....4xqX</span>08 Jul, 18 10:20PM</td>
                                                <td class="tranx-action">
                                                    <a href="#" data-bs-toggle="modal" data-bs-target="#tranxApproved"><em class="ti ti-more-alt"></em></a>
                                                </td>
                                            </tr><tr class="odd">
                                                <td class="tranx-status tranx-status-approved"><span class="d-none">Approved</span><em class="ti ti-check"></em></td>
                                                <td class="tranx-no"><span>ICIYOW0102</span>08 Jul, 18 10:20PM</td>
                                                <td class="tranx-token"><span>+5,600</span>ICOX</td>
                                                <td class="tranx-amount"><span>56.00</span>ETH <em class="fas fa-info-circle" data-bs-toggle="tooltip" data-placement="bottom" title="" data-bs-original-title="1 ETH = 100 ICOX" aria-label="1 ETH = 100 ICOX"></em></td>
                                                <td class="tranx-from"><span>1F1t....4xqX</span>08 Jul, 18 10:20PM</td>
                                                <td class="tranx-action">
                                                    <a href="#" data-bs-toggle="modal" data-bs-target="#tranxApproved"><em class="ti ti-more-alt"></em></a>
                                                </td>
                                            </tr> */}

                                        </tbody>
                                        <Lister/>
                                    </table></div></div></div>
                                    {/* <div class="row"><div class="col-sm-3 text-start"><div class="dataTables_paginate dataTables_paginate2 paging_simple_numbers" id="DataTables_Table_0_paginate"><ul class="pagination"><li class="paginate_button page-item previous  disabled" id="DataTables_Table_0_previous"><a href="#" aria-controls="DataTables_Table_0" data-dt-idx="0" tabindex="0" class="page-link">Prev</a></li><li class="paginate_button page-item active"><a href="#" aria-controls="DataTables_Table_0" data-dt-idx="1" tabindex="0" class="page-link">1</a></li><li class="paginate_button page-item "><a href="#" aria-controls="DataTables_Table_0" data-dt-idx="2" tabindex="0" class="page-link">2</a></li><li class="paginate_button page-item next" id="DataTables_Table_0_next"><a href="#" aria-controls="DataTables_Table_0" data-dt-idx="3" tabindex="0" class="page-link">Next</a></li></ul></div></div><div class="col-sm-9 text-sm-end pagi-num"><div class="dataTables_info" id="DataTables_Table_0_info" role="status" aria-live="polite">1 -7 of 10</div></div></div> */}
                                </div>
                            </div>
                        </div>
                    {/* </div>
                </div>
            </div> */}
            {/* modal start */}
            <div class="modal fade" id="tranxApproved" tabindex="-1" aria-modal="true" role="dialog">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <a href="#" class="modal-close modal-close2" data-bs-dismiss="modal" aria-label="Close"><em class="ti ti-close"></em></a>
                        <div class="tranx-popup">
                            <h5>View Transaction Details</h5>
                            <p>Transaction <strong>ICIYOW0102</strong> was place on <strong>24-Jul-2022, 12:10 am</strong> and <br /> it’s <strong>Successfully Paid.</strong></p>
                            <div class="tranx-purchase-details">
                                <h6>Purchase Details</h6>
                                <ul class="tranx-purchase-info">
                                    <li>
                                        <div class="tranx-purchase-head">Package Name</div>
                                        <div class="tranx-purchase-des">ICO Phace 3</div>
                                    </li>
                                    <li>
                                        <div class="tranx-purchase-head">Payment Method</div>
                                        <div class="tranx-purchase-des">ETH</div>
                                    </li>
                                    <li>
                                        <div class="tranx-purchase-head">Your Contribution</div>
                                        <div class="tranx-purchase-des">1</div>
                                    </li>
                                    <li>
                                        <div class="tranx-purchase-head">Token (T)</div>
                                        <div class="tranx-purchase-des">
                                            <span>4,780.00</span>
                                            <span>(4780 * 1)</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="tranx-purchase-head">Bonus Tokens (B)</div>
                                        <div class="tranx-purchase-des">
                                            <span>956.00</span>
                                            <span>(956 * 1)</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="tranx-purchase-head">Total Tokens</div>
                                        <div class="tranx-purchase-des">
                                            <span>5,736.00</span>
                                            <span>(T+B)</span>
                                        </div>
                                    </li>
                                </ul>
                                <h6>Payment Deposit From <em class="ti ti-arrow-right"></em> <span>Ethereum Address</span></h6>
                                <div class="tranx-payment-info">
                                    <em class="fab fa-ethereum"></em>
                                    <input type="text" class="tranx-payment-address" value="0x4156d3342d5c385a87d264f90653733592000581" disabled="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* modal end */}
            <div class="footer-bar">
                <div class="container">
                    <div class="row">
                        <div class="col-md-7">
                            <span class="footer-copyright">Copyright 2022, <a href="#">Explicit</a>. All Rights Reserved.</span>
                        </div>
                        <div class="col-md-5 text-md-end">
                            <ul class="footer-links">
                                <li><a href="#">Privacy Policy</a></li>
                                <li><a href="#">Terms of Sales</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Transactions