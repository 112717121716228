import React from 'react'
import '../assets/css/style.css'
import '../assets/css/vendor.bundle.css'
import '../assets/css/style-salvia.css'
import '../assets/css/theme.css'
import instagram from "../images/home/instagram.png"
import twitter from "../images/home/twitter.png"
import telegram from "../images/home/telegram.png"
import game from "../images/home/game.png"
import logo_full_white from "../images/logo-full-white.png"
import ScrollAnimation from 'react-animate-on-scroll';
const Footer = () => {
    return (


        <footer class="nk-footer bg-theme-alt section-connect footer-sec">
            <div className='footer-overlay'></div>
            <div class="section section-m pb-0 tc-light ov-h has-ovm">
                <div class="container py-4">

                    <div class="nk-block pb-lg-5">
                        <div class="row justify-content-center text-center">
                            <div class="col-lg-6 col-md-9">
                                <ScrollAnimation animateIn="fadeInUp" delay="0.5">
                                    <div class="wide-auto-sm section-head section-head-sm pdb-r">
                                        <h4 class="title title-md animated fadeInUp">Don't miss out, Stay updated</h4>
                                    </div>
                                </ScrollAnimation>
                                <ScrollAnimation animateIn="fadeInUp" delay="0.5">
                                <form action="form/subscribe.php" class="nk-form-submit" method="post" novalidate="novalidate">
                                    <div class="field-inline field-inline-round field-inline-s2-sm  shadow-soft animated fadeInUp" >
                                        <div class="field-wrap">
                                            <input class="input-solid input-solid-md required email" type="text" name="contact-email" placeholder="Enter your email" />
                                            <input type="text" class="d-none" name="form-anti-honeypot" value="" />
                                        </div>
                                        <div class="submit-wrap">
                                            <button class="btn btn-md btn-round btn-grad h-100 btn-primary">Subscribe</button>
                                        </div>
                                    </div>
                                    <div class="form-results"></div>
                                </form>
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="nk-ovm shape-contain shape-center-top shape-p"></div>
            </div>
            <div class="section section-footer section-s tc-light bg-transparent">
                <div class="container">

                    <div class="nk-block block-footer">
                        <div class="row">
                            <div class="col">
                                <div class="wgs wgs-text text-center mb-3">
                                        <ul class="team-social team-social-s2 banner-social-media">
                                            <li><a href="#"><img src={telegram} className="social-img"></img></a></li>
                                            <li><a href="#"><img src={game} className="social-img"></img></a></li>
                                            <li><a href="#"><img src={twitter} className="social-img"></img></a></li>
                                            <li><a href="#"><img src={instagram} className="social-img"></img></a></li>
                                        </ul>
                                    <div class="copyright-text copyright-text-s3 pdt-m copyright-center-para">
                                        <p><span class="d-sm-block">Copyright © 2022, <a href="./">ICO Crypto. </a>Tamplate made by Softnio &  &amp; Handcrafted by iO. </span>All trademarks and copyrights belong to their respective owners.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer