import * as anchor from '@project-serum/anchor'
import { getAssociatedTokenAddress } from '@solana/spl-token'

export async function getTokenAccountHistory(connection, accountOwner){ 
    const token_id = new anchor.web3.PublicKey(
      'd55cgyF3QV32UWHUp3j2yvyDUz5ywg713C1Q6Adxmhj',
    )

    const tokenAccount = await getAssociatedTokenAddress(token_id, accountOwner)
    if ((await connection.getAccountInfo(tokenAccount)) === null) {
      // console.log('you never buy the token :')
      return null
    }

    const history = await connection.getSignaturesForAddress(tokenAccount)
    // console.log("Token account History Fetched ...");

    return history;
}
export default getTokenAccountHistory;