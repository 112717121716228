import React, { useEffect, useState } from 'react'
import '../Dashboard/Dashboard.css'
import '../../admin-assets/css/style.css'
import '../../admin-assets/css/vendor.bundle.css'
// import '../../admin-assets/js/script'
import logo from '../../images/home/03a.png'
import userthumbsm from '../../admin-assets/images/user-thumb-sm.png'
import userthumblg from '../../admin-assets/images/user-thumb-lg.png'
import interest from '../../admin-assets/images/vector-a.png'
import $ from 'jquery'
import RangeSlider from 'react-bootstrap-range-slider';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import TopBar from '../TopBar'
import Sidebar from '../Sidebar'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Account = () => {
    $(document).ready(function () {
        $('.toggle-nav').click(function () {
            $('.open-mobile-sidebaar').toggleClass('active');
        })
    })

    const [value, setValue] = useState(0);
    const [userName, setUserName] = useState('');
    const [email, setEmail] = useState('');
    const [userNameError, setUserNameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [id, setId] = useState('');
    const [user, setUser] = useState("");
    const [isResend,setIsResend ] = useState(false);
  const auth = localStorage.getItem("auth");


    const getUserData = () => {
        const email = localStorage.getItem("loginUser");

        let userData = new URLSearchParams();

        userData.append("email", email);
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded", auth },
            body: userData,
        };

        fetch("https://pms.virtualheight.com/explicit/explicit/getuser", requestOptions)
        // fetch("http://localhost:3001/getuser", requestOptions)
            .then((response) => response.json())
            .then((data) => {
                console.log(data.data.userName, "hh12");
                setUser(data.data);
                setUserName(data.data.userName);
                setEmail(data.data.email);
            });
    };
    
    const resendMail = () => {
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded", auth },
        };

        fetch("https://pms.virtualheight.com/explicit/explicit/email/resend", requestOptions)
        // fetch("http://localhost:3001/email/resend", requestOptions)
            .then((response) => response.json())
            .then((data) => {
                toast.success('Mail sended')
                console.log(data.data.userName, "aaaaaaaa");
                setIsResend(true)
            });
    };

    useEffect(() => {
        getUserData();
    }, []);
    //     useEffect(()=>{
    // console.log(window.solana.publicKey.toBase58(),"d2");
    //     },[])

    const updateHandler = () => {
        console.log(id, 'enter');
        if (userName === '') {
            setUserNameError('Please enter userName')
        } else {
            setUserNameError('')
        }

        if (email === '') {
            setEmailError('Please enter email')
        } else {
            setEmailError('')
        }


        if (userName !== '' &&

            email !== ''

        ) {
            let userData = new URLSearchParams();
            if (window.solana?.publicKey?.toBase58()) {
                userData.append('userName', userName);
                userData.append('email', email);
                if (id != "") {
                    userData.append('id', id);
                }
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded',auth  },
                    body: userData

                };

                fetch('https://pms.virtualheight.com/explicit/explicit/updateuser', requestOptions)
                // fetch("http://localhost:3001/updateuser", requestOptions)

                    .then(response => response.json())
                    .then(data => {
                        // setUserName(data.data.userName)
                        // setEmail(data.data.email)
                        setId(data.data._id)
                        toast.success(`${data.message} please check mail and verify`)
                        getUserData();

                    });
            }
            else {
                toast.error('Please connect wallate')
            }
        }
    }

    useEffect(() => {
        const email = localStorage.getItem("loginUser");
        const auth = localStorage.getItem("auth");

        // console.log(window.solana?.publicKey?.toBase58(),"d4");
        if (window.solana.publicKey) {
            let userData = new URLSearchParams();
            userData.append('email', email);
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded', auth },
                body: userData
            };

            fetch('https://pms.virtualheight.com/explicit/explicit/getuser', requestOptions)
                .then(response => response.json())
                .then(data => {
                    console.log(data, 'kk12')
                    setUserName(data.data.userName)
                    // setLastName(data.data.lastName)
                    setEmail(data.data.email)
                    // setMobileNo(data.data.mobileNo)
                    // setDateOfBirth(new Date(data.data.dateOfBirth))
                    // setNationality(data.data.nationality)
                    setId(data.data._id)
                });
        }
    }, [window.solana.publicKey])



    return (
        <div className='user-dashboard'>
            {/* <TopBar /> */}
            {/* <div className='user-wraper'>
                <div class="container">
                    <div class="d-flex">
                        <Sidebar /> */}
            <div className='user-content'>
                <div class="user-panel user-panel-title2">
                    <h2 class="user-panel-title">Account Information</h2>
                    {!user?.verificationStatus&&<div class="alert-box alert-primary">
                        <div class="alert-txt alert-txt2 "><em class="ti ti-alert"></em>Check your email to confirm your email address.<br /> If it's not available in your inbox, please check the spam box</div>
                        {user?.verificationStatus ? <a  class="btn btn-sm btn-primary resend-btn">Verified</a> 
                        : <button disabled={isResend} onClick={resendMail} class="btn btn-sm btn-primary resend-btn">Resend Email</button>
                            
                        }
                    </div>}
                    {console.log(user?.verificationStatus, 'user?.verificationStatus')}
                    <ul class="nav nav-tabs nav-tabs-line" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link active" data-bs-toggle="tab" href="#personal-data">Personal Data</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-bs-toggle="tab" href="#wallet-address">Wallet Address</a>
                        </li>
                        {/* <li class="nav-item">
                                        <a class="nav-link" data-bs-toggle="tab" href="#notification">Notification</a>
                                    </li> */}
                    </ul>
                    <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade active show" id="personal-data">
                            {/* <form> */}
                            <div class="input-item input-with-label">
                                <label for="full-name" class="input-item-label">Username</label>
                                <input class="input-bordered" type="text" id="full-name" name="full-name" value={userName} onChange={(e) => { setUserName(e.target.value) }} />
                                <p style={{ color: 'red' }}>{userNameError}</p>
                            </div>

                            <div class="input-item input-with-label">
                                <label for="email-address" class="input-item-label">Email Address</label>
                                <input class="input-bordered" type="text" id="email-address" name="email-address" value={email} onChange={(e) => { setEmail(e.target.value) }} />
                                <p style={{ color: 'red' }}>{emailError}</p>
                            </div>

                            <div class="gaps-1x"></div>
                            <div class="d-sm-flex justify-content-between align-items-center update">
                                <button class="btn btn-primary resend-btn update-btn" onClick={() => updateHandler()}>Update</button>

                                <div class="gaps-2x d-sm-none"></div>
                                <span class="color-success"><em class="ti ti-check-box"></em> All Changes are saved</span>
                            </div>
                            {/* </form> */}
                        </div>
                        <div class="tab-pane fade" id="wallet-address">
                            <form action="#">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="input-item input-with-label">
                                            <label for="swalllet" class="input-item-label">Wallet Name</label>
                                            <input class="input-bordered input-bordered5" type="text" id="token-address" name="token-address" disabled value="Phantom" />
                                        </div>
                                    </div>
                                </div>
                                <div class="input-item input-with-label">
                                    <label for="token-address" class="input-item-label">Your Address for tokens:</label>
                                    <input class="input-bordered input-bordered5" type="text" id="token-address" name="token-address" disabled value={window.solana?.publicKey?.toBase58() ?? ''} />
                                </div>
                                <div class="gaps-2x"></div>

                            </form>
                        </div>
                        <div class="tab-pane fade tab-pane2" id="notification">
                            <p>You can manage your all kind of notification from here.</p>
                            <div class="gaps-1x"></div>
                            <ul class="notification-list">
                                <li>
                                    <span>Notify me by email about resumption of sales</span>
                                    <div class="padl-2x"><input class="input-switch" type="checkbox" id="rsmpts" /><label for="rsmpts"></label></div>
                                </li>
                                <li>
                                    <span>Notify me by email for latest news</span>
                                    <div class="padl-2x"><input class="input-switch" type="checkbox" id="lnews" /><label for="lnews"></label></div>
                                </li>
                                <li>
                                    <span>Alert me by email for unusual activity.</span>
                                    <div class="padl-2x"><input class="input-switch" type="checkbox" id="unuact" /><label for="unuact"></label></div>
                                </li>
                            </ul>
                            <div class="gaps-4x"></div>
                            <div class="d-flex justify-content-between align-items-center">
                                <span></span>
                                <span class="color-success"><em class="ti ti-check-box"></em> Update Notification Settings</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* </div>
                </div>
            </div> */}
            <div class="footer-bar">
                <div class="container">
                    <div class="row">
                        <div class="col-md-7">
                            <span class="footer-copyright">Copyright 2022, <a href="#">Explicit</a>. All Rights Reserved.</span>
                        </div>
                        <div class="col-md-5 text-md-end">
                            <ul class="footer-links">
                                <li><a href="#">Privacy Policy</a></li>
                                <li><a href="#">Terms of Sales</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Account