import React, { useState } from 'react'
import { BrowserRouter, Routes, Route ,Navigate} from "react-router-dom";
import Home from './components/Home';
import Dashboard from './components/Dashboard/Dashboard';
import Transactions from './components/Dashboard/Transactions';
import Kycapplication from './components/Dashboard/Kycapplication';
import Account from './components/Dashboard/Account';


import Contributions from './components/Dashboard/Contributions';
// import Referral from './components/Dashboard/Referral';
import Security from './components/Dashboard/Security';
import Topcomponent from './components/Dashboard/Topcomponent';
import Login from './container/login/Login';
import Registration from './container/register/Registration';
import  VarifyMail  from './components/VarifyMail';


const AppRoutes = () => {
  const [isUserLogin, setIsUserLogin] = useState("")
  const isLogin = localStorage.getItem('isUserLogin', window.btoa("true"))

  console.log(isUserLogin, "isUserLogin");

  useState(() => {
    setIsUserLogin(window.atob(isLogin) === "true" ? window.atob(isLogin) : "");
  }, [isLogin,isUserLogin])

  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/signin" element={<Login />} />
        <Route path="/signup" element={<Registration />} />
        <Route path="/verifymail/:id" element={<VarifyMail />} />
        {
          isUserLogin === "true" ?
            <>
              <Route path="/" element={<Topcomponent />}>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/kycapplication" element={<Kycapplication />} />
                <Route path="/transactions" element={<Transactions />} />
                <Route path="/account" element={<Account />} />

                <Route path="/contributions" element={<Contributions />} />
                {/* <Route path="/referral" element={<Referral />} /> */}
                <Route path="/security" element={<Security />} />
              </Route>
            </> : <Route
              path="*"
              element={<Navigate to="/signin" replace />}
            />
        }

      </Routes>
    </BrowserRouter>
  )
}

export default AppRoutes