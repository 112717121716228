import React, { useState } from 'react'
import '../Dashboard/Dashboard.css'
import '../../admin-assets/css/style.css'
import '../../admin-assets/css/vendor.bundle.css'
// import '../../admin-assets/js/script'
import logo from '../../images/home/03a.png'
import etherium from '../../assets/images/icon-ethereum.png'
import lightcoin from '../../assets/images/icon-lightcoin.png'
import userthumbsm from '../../admin-assets/images/user-thumb-sm.png'
import userthumblg from '../../admin-assets/images/user-thumb-lg.png'
import interest from '../../admin-assets/images/vector-a.png'
import $ from 'jquery'
import RangeSlider from 'react-bootstrap-range-slider';
import TopBar from '../TopBar'
import Sidebar from '../Sidebar'

const Security = () => {
    $(document).ready(function () {
        $('.toggle-nav').click(function () {
            $('.open-mobile-sidebaar').toggleClass('active');
        })
    })

    const [value, setValue] = useState(0);

    return (
        <div className='user-dashboard'>
           {/* <TopBar /> */}

            {/* <div className='user-wraper'>
                <div class="container">
                    <div class="d-flex">
                     <Sidebar /> */}

                        <div className='user-content  user-content-two'>
                            <div class="user-panel user-panel-two">
                                <h2 class="user-panel-title user-panel-title-two">Security Settings</h2>
                                <p>You can control your password and account-access setting and toos that let you safe, protect your account.</p>
                                <ul class="nav nav-tabs nav-tabs-line nav-tabs-line-two " role="tablist">
                                    <li class="nav-item">
                                        <a class="nav-link active" data-bs-toggle="tab" href="#security-opt">Security</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" data-bs-toggle="tab" href="#password-opt">Change Password</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" href="activity.html">Activity Log</a>
                                    </li>
                                </ul>
                                <div class="tab-content" id="security-opt-tab">
                                    <div class="tab-pane fade active show" id="security-opt">
                                        <h5 class="user-panel-subtitle user-panel-subtitle-two">Genaral Security Options</h5>
                                        <div class="gaps-1x"></div>
                                        <ul class="notification-list">
                                            <li class="justify-content-start guttar-16px">
                                                <div><input class="input-switch" type="checkbox" id="activitylog" /><label for="activitylog" ></label></div>
                                                <div><span>Save my Activities Log</span></div>
                                            </li>
                                            <li class="justify-content-start guttar-16px">
                                                <div><input class="input-switch" type="checkbox" id="passchange" /><label for="passchange" ></label></div>
                                                <div><span>Confirm me through email before password change</span></div>
                                            </li>
                                            <li class="justify-content-start guttar-16px">
                                                <div><input class="input-switch" type="checkbox" id="tokwith" /><label for="tokwith" ></label></div>
                                                <div><span>Ask me password before token withdraw</span></div>
                                            </li>
                                        </ul>
                                        <div class="gaps-2x"></div>
                                        <div class="sap"></div>
                                        <h5 class="user-panel-subtitle user-panel-subtitle-two ">Two-Factor Security Option</h5>
                                        <p>Two-factor authentication is a method for protection your web account. When it is activated you need to enter not only your password, but also a special code. You can receive this code by in mobile app. Even if third person will find your password, then can't access with that code.</p>
                                        <div class="gaps-2x"></div>
                                        <div class="d-flex guttar-20px">
                                            <div class="currnt-status"><span>Current Status:</span></div>
                                            <div><span class="alert alert-xs alert-dark alert-dsable">Disabled</span></div>
                                        </div>
                                        <div class="gaps-4x"></div>
                                        <a class="ath-trigger" href="#">
                                            <span class="simple-switch"></span>Enable and Use an authenticator App </a>
                                        <div class="gaps-1x"></div>
                                        <div class="ath-content">
                                            <div class="gaps-2x"></div>
                                            <h5>1) Install an authentication app on your device. Any app that supports the Time-based One-Time Password (TOTP) protocol should work, including:</h5>
                                            <ul class="ath-content-sublist">
                                                <li><a href="#">Google Authenticator</a> (Android/iOS)</li>
                                                <li><a href="#">Authy</a> (Android/iOS)</li>
                                                <li><a href="#">Microsoft Authenticator</a> (Windows Phone)</li>
                                            </ul>
                                            <h5>2) Use the authenticator app to scan the barcode below.</h5>
                                            <img class="ath-content-qrimg" src="images/eth-qr.png" alt="qr" />
                                            <div class="gaps-2x"></div>
                                            <h5>3) Enter the code generated by the authenticator app.</h5>
                                            <form action="#">
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <div class="input-item input-with-label">
                                                            <input class="input-bordered" type="text" id="security-code" name="security-code" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <button class="btn btn-primary">verify</button>
                                            </form>
                                        </div>
                                        <div class="gaps-4x"></div>
                                        <div class="note note-plane note-danger">
                                            <em class="fas fa-info-circle"></em>
                                            <p>Important! In case of loss of access to the mobile application, you can regain it using mobile number that specified in your profile. If you don't save that, we will not able to help you to regain.</p>
                                        </div>
                                        <div class="gaps-4x"></div>
                                        <div class="d-flex justify-content-between align-items-center">
                                            <span></span>
                                            <span class="color-success"><em class="ti ti-check-box"></em> Update Settings</span>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="password-opt">
                                        <form action="#">
                                            <div class="row">
                                                <div class="col-lg-6">
                                                    <div class="input-item input-with-label">
                                                        <label for="swalllet" class="input-item-label">Old Password</label>
                                                        <input class="input-bordered" type="password" name="old-password" value="" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-lg-6">
                                                    <div class="input-item input-with-label">
                                                        <label for="date-of-birth" class="input-item-label">New Password</label>
                                                        <input class="input-bordered" type="password" name="new-password" />
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="input-item input-with-label">
                                                        <label for="date-of-birth" class="input-item-label">Confirm New Password</label>
                                                        <input class="input-bordered" type="password" name="re-password" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="note note-plane note-info">
                                                <em class="fas fa-info-circle"></em>
                                                <p>Password should be minmum 8 letter and include lower and uppercase letter.</p>
                                            </div>
                                            <div class="gaps-3x"></div>
                                            <div class="gaps-1x"></div>
                                            <div class="d-sm-flex justify-content-between align-items-center">
                                                <button class="btn btn-primary update-btn">Update</button>
                                                <div class="gaps-2x d-sm-none"></div>
                                                <span class="color-success"><em class="ti ti-check-box"></em> Changed Password</span>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    {/* </div>
                </div>
            </div> */}

            <div class="footer-bar">
                <div class="container">
                    <div class="row">
                        <div class="col-md-7">
                            <span class="footer-copyright">Copyright 2022, <a href="#">Explicit</a>. All Rights Reserved.</span>
                        </div>
                        <div class="col-md-5 text-md-end">
                            <ul class="footer-links">
                                <li><a href="#">Privacy Policy</a></li>
                                <li><a href="#">Terms of Sales</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Security