import React, { useState } from 'react'
import '../Dashboard/Dashboard.css'
import '../../admin-assets/css/style.css'
import '../../admin-assets/css/vendor.bundle.css'
// import '../../admin-assets/js/script'
import logo from '../../images/home/03a.png'
import userthumbsm from '../../admin-assets/images/user-thumb-sm.png'
import userthumblg from '../../admin-assets/images/user-thumb-lg.png'
import interest from '../../admin-assets/images/vector-a.png'
import $ from 'jquery'
import RangeSlider from 'react-bootstrap-range-slider';
// import Sidebar from '../Sidebar'
import TopBar from '../TopBar'

const Kycapplication = () => {
    $(document).ready(function () {
        $('.toggle-nav').click(function () {
            $('.open-mobile-sidebaar').toggleClass('active');
        })
    })

    const [value, setValue] = useState(0);

    return (
        <div className='user-dashboard'>
        {/* <TopBar /> */}
            {/* <div className='user-wraper'>
                <div class="container">
                    <div class="d-flex">
                        <Sidebar /> */}
                        <div className='user-content'>
                            <div class="user-panel user-panel-title2">
                                <h2 class="user-panel-title">Identity Verification - KYC</h2>
                                <p>To comply with regulation each participant will have to go through indentity verification (KYC). So please complete our fast and secure verification process to participate in our token sale. You can proceed from here to verify your indentity and also you can check your application status if you submit already. </p>
                                <div class="gaps-2x"></div>
                                <div class="status status-empty">
                                    <div class="status-icon">
                                        <em class="ti ti-files"></em>
                                        <div class="status-icon-sm">
                                            <em class="ti ti-close"></em>
                                        </div>
                                    </div>
                                    <span class="status-text status-text2">You have not submitted your KYC Application</span>
                                    <a href="kyc-application.html" class="btn btn-primary custom-btn-kyc">CLick to proceed</a>
                                </div>
                                <div class="note note-md note-info note-plane note-plane2">
                                    <em class="fas fa-info-circle"></em>
                                    <p>Some of contries and regions will not able to pass KYC process and therefore are restricted from token sale.</p>
                                </div>
                            </div>
                        </div>
                    {/* </div>
                </div>
            </div> */}
            <div class="footer-bar">
                <div class="container">
                    <div class="row">
                        <div class="col-md-7">
                            <span class="footer-copyright">Copyright 2022, <a href="#">Explicit</a>. All Rights Reserved.</span>
                        </div>
                        <div class="col-md-5 text-md-end">
                            <ul class="footer-links">
                                <li><a href="#">Privacy Policy</a></li>
                                <li><a href="#">Terms of Sales</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Kycapplication