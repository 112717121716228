import React, { useEffect, useState } from "react";
import "../Dashboard/Dashboard.css";
import "../../admin-assets/css/style.css";
import "../../admin-assets/css/vendor.bundle.css";
// import '../../admin-assets/js/script'
import logo from "../../images/home/03a.png";
// import etherium from '../../assets/images/icon-ethereum.png'
// import lightcoin from '../../assets/images/icon-lightcoin.png'
import sol from "../../assets/images/sol.png";
import usdc from "../../assets/images/usdc.png";
import ray from "../../assets/images/ray.png";
import srm from "../../assets/images/srm.png";

import userthumbsm from "../../admin-assets/images/user-thumb-sm.png";
import userthumblg from "../../admin-assets/images/user-thumb-lg.png";
import interest from "../../admin-assets/images/vector-a.png";
import $ from "jquery";
import RangeSlider from "react-bootstrap-range-slider";
import TopBar from "../TopBar";
// import Sidebar from '../Sidebar'

import { Buy } from "../../utils/Buy";
import { SetPrice } from "../../utils/SetPrice";

const Contributions = () => {
  $(document).ready(function () {
    $(".toggle-nav").click(function () {
      $(".open-mobile-sidebaar").toggleClass("active");
    });
  });

  let [tokenPriceMap, setTokenPrices] = useState(new Map());

  const [tokentype, setTokenType] = useState();

  let tokenType = new Map();
  tokenType.set("PAY_SOL", 0);
  tokenType.set("PAY_RAY", 1);
  tokenType.set("PAY_SRM", 2);
  tokenType.set("PAY_USDC", 3);

  let [value, setValue] = useState(0);
  const [currentPaymentMethod, setPaymentMethod] = useState("PAY_SOL");
  const [tokenAmount, setTokenAmount] = useState(0);
  const [tokenPrice, setTokenPrice] = useState(0);
  const calcAndUpdatePrice = (e) => {
    let totalPrice = e.target.value * tokenPriceMap.get(currentPaymentMethod);
    setTokenPrice(totalPrice);
    setTokenAmount(e.target.value);
  };

  useEffect(() => {
    let totalPrice = tokenAmount * tokenPriceMap.get(currentPaymentMethod);
    setTokenPrice(totalPrice);
  }, [currentPaymentMethod]);

  return (
    <div className="user-dashboard">
      {/* <TopBar /> */}
      <SetPrice setTokenPrices={setTokenPrices} />
      {/* <div className="user-wraper">
        <div class="container">
          <div class="d-flex">
            <Sidebar /> */}
      <div className="user-content">
        <div class="user-panel panel-custom">
          <h2 class="user-panel-title user-panel-title-3">Buy Tokens</h2>
          <form action="#">
            <h5 class="user-panel-subtitle user-panel-subtitle-3">
              01. Select the payment method and calculate token price
            </h5>
            <div class="gaps-1x"></div>
            <div class="payment-list">
              <div class="row">
                <div class="col-md-3 col-sm-6">
                  <div class="payment-item payment-item-one ">
                    <input
                      class="payment-check"
                      type="radio"
                      id="payeth"
                      name="SOL"
                      value="tranxETH"
                      // checked //!  it creates problem in onChange event.
                      // and also some bug here.
                      onChange={(e) => {
                        setPaymentMethod("PAY_SOL");
                        setTokenType(e.target.name);
                      }}
                    ></input>
                    <label for="payeth">
                      <div class="payment-icon">
                        <img class="payment-icon" src={sol} alt="logo" />
                      </div>
                      <span class="payment-cur payment-cur-team">SOL</span>
                    </label>
                    <span>@ {tokenPriceMap.get("PAY_SOL")} SOL</span>
                  </div>
                </div>
                <div class="col-md-3 col-sm-6">
                  <div class="payment-item payment-item-one">
                    <input
                      class="payment-check"
                      type="radio"
                      id="paylightcoin"
                      name="RAY"
                      value="tranxLTC"
                      onChange={(e) => {
                        setPaymentMethod("PAY_RAY");
                        setTokenType(e.target.name);
                      }}
                    ></input>
                    <label for="paylightcoin">
                      <div class="payment-icon">
                        <img class="payment-icon" src={ray} alt="icon"></img>
                      </div>
                      <span class="payment-cur payment-cur3">RAY</span>
                    </label>
                    <span>@ {tokenPriceMap.get("PAY_RAY")} RAY</span>
                  </div>
                </div>
                <div class="col-md-3 col-sm-6">
                  <div class="payment-item payment-item-one">
                    <input
                      class="payment-check"
                      type="radio"
                      id="paybtc"
                      name="SRM"
                      value="tranxBTC"
                      onChange={(e) => {
                        setPaymentMethod("PAY_SRM");
                        setTokenType(e.target.name);
                      }}
                    ></input>
                    <label for="paybtc">
                      <div class="payment-icon">
                        {/* <em class="payment-icon fab fa-btc"></em> */}
                        <img class="payment-icon" src={srm} alt="icon"></img>
                      </div>
                      <span class="payment-cur payment-cur3">SRM</span>
                    </label>
                    <span>@ {tokenPriceMap.get("PAY_SRM")} SRM</span>
                  </div>
                </div>
                <div class="col-md-3 col-sm-6">
                  <div class="payment-item payment-item-one">
                    <input
                      class="payment-check"
                      type="radio"
                      id="payusd"
                      name="USDC"
                      value="tranxUSD"
                      onChange={(e) => {
                        setPaymentMethod("PAY_USDC");
                        setTokenType(e.target.name);
                      }}
                    ></input>
                    <label for="payusd">
                      <div class="payment-icon payment-icon-usd">
                        {/* <em class="payment-icon fas fa-credit-card"></em> */}
                        <img class="payment-icon" src={usdc} alt="icon"></img>
                      </div>
                      <span class="payment-cur payment-cur3">USDC</span>
                    </label>
                    <span>@ {tokenPriceMap.get("PAY_USDC")} USDC</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="gaps-1x"></div>
            <h5 class="user-panel-subtitle user-panel-subtitle3 ">
              02. Set amount of XPLCT tokens you would like to purchase
            </h5>
            <p>
              To be a part of the Explicit project and purchase XPLCT token, you
              must first connect your Phantom Wallet. Select the payment method
              and enter the amount of XPLCT tokens you wish to purchase. You can
              buy XPLCT tokens using SOL, RAY, SRM, or USDC.
            </p>
            <div class="gaps-1x"></div>
            <div class="row">
              <div class="col-md-8">
                <div class="payment-calculator payment-calculator3">
                  <div class="payment-get usd-tcx">
                    <label for="paymentGet">Tokens to Purchase</label>
                    <div class="payment-input">
                      <input
                        class="input-bordered"
                        type="number"
                        min="0"
                        id="paymentGet"
                        onChange={(e) => calcAndUpdatePrice(e)}
                      ></input>
                      <span class="payment-get-cur payment-cal-cur payment-cal-cur3">
                        XPLCT
                      </span>
                    </div>
                  </div>
                  {/* <em class="ti ti-exchange-vertical arrow-img"></em> */}
                  {/* <div class="payment-from usd-tcx"> */}
                  {/*   <label for="paymentFrom">Payment Amount</label> */}
                  {/*   <div class="payment-input"> */}
                  {/*     <input class="input-bordered" type="text" id="paymentFrom" value="600"></input> */}
                  {/*     <span class="payment-from-cur payment-cal-cur payment-cal-cur3">usd</span> */}
                  {/*   </div> */}
                  {/* </div> */}
                </div>
                {/* <div class="gaps-2x d-md-none"></div> */}
              </div>
              {/* <div class="col-md-4"> */}
              {/*   <div class="payment-bonus payment-bonus3"> */}
              {/*     <h6 class="payment-bonus-title  payment-bonus-title3 ">Current Bonus</h6> */}
              {/*     <span class="payment-bonus-amount payment-bonus-amount3 ">20% <span>on pre-sales</span></span> */}
              {/*     <span class="payment-bonus-time payment-bonus-time-two">End at - 09 Jul, 2022</span> */}
              {/*   </div> */}
              {/*   <div class="gaps-1x d-md-none"></div> */}
              {/* </div> */}
            </div>
            <div class="gaps-1x"></div>
            <div class="payment-calculator-note">
              <i class="fas fa-info-circle"></i>The calculator helps you to
              convert required currency to XPLCT tokens.
            </div>
            <div class="gaps-3x"></div>
            <div class="payment-summary">
              <div class="row">
                <div class="col-md-4">
                  <div class="payment-summary-item payment-summary-final">
                    <h6 class="payment-summary-title">Final Payment</h6>
                    <div class="payment-summary-info ">
                      <span
                        class="payment-summary-amount payment-summary-amount3"
                        id="final_payment"
                      >
                        {tokenPrice}
                      </span>{" "}
                      <span>{tokentype}</span>
                    </div>
                  </div>
                </div>
                {/* <div class="col-md-4">
                        <div class="payment-summary-item payment-summary-bonus">
                          <h6 class="payment-summary-title">Received Bonus</h6>
                          <div class="payment-summary-info">
                            <span>+</span>{' '}
                            <span class="payment-summary-amount  payment-summary-amount3">
                              600.00
                            </span>{' '}
                            <span>USD</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="payment-summary-item payment-summary-tokens">
                          <h6 class="payment-summary-title">Tokens Received</h6>
                          <div class="payment-summary-info">
                            <span class="payment-summary-amount payment-summary-amount3">
                              12,200
                            </span>{' '}
                            <span>USD</span>
                          </div>
                        </div>
                      </div> */}
              </div>
            </div>
            {/* //? This link tag commenteed and added the button with same style in  the Buy component. */}
            {/* <a
                    href="#"
                    class="btn btn-primary payment-btn tokenbtn"
                    data-bs-toggle="modal"
                    data-bs-target="#tranxETH"
                  >
                    Purchase Tokens
                  </a> */}
          </form>
          {/* //? purchase */}
          <Buy
            tokenAmount={tokenAmount}
            tokenType={tokenType.get(currentPaymentMethod)}
          />
        </div>
      </div>
      {/* </div>
        </div>
      </div> */}

      <div class="footer-bar">
        <div class="container">
          <div class="row">
            <div class="col-md-7">
              <span class="footer-copyright">
                Copyright 2022, <a href="#">Explicit</a>. All Rights Reserved.
              </span>
            </div>
            <div class="col-md-5 text-md-end">
              <ul class="footer-links">
                <li>
                  <a href="#">Privacy Policy</a>
                </li>
                <li>
                  <a href="#">Terms of Sales</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contributions;
