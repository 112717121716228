import React, { useEffect, useState } from 'react'
import '../Dashboard/Dashboard.css'
import '../../admin-assets/css/style.css'
import '../../admin-assets/css/vendor.bundle.css'
// import '../../admin-assets/js/script'
import logo from '../../images/home/03a.png'
import userthumbsm from '../../admin-assets/images/user-thumb-sm.png'
import userthumblg from '../../admin-assets/images/user-thumb-lg.png'
import interest from '../../admin-assets/images/vector-a.png'
import $ from 'jquery'
import RangeSlider from 'react-bootstrap-range-slider'
import Sidebar from '../Sidebar'
import TopBar from '../TopBar'
import { SetPrice } from '../../utils/SetPrice'
import { GetDetails } from '../../utils/GetDetails'
import { GetUserContrib } from '../../utils/CreateList'

const Dashboard = () => {
  $(document).ready(function () {
    $('.toggle-nav').click(function () {
      $('.open-mobile-sidebaar').toggleClass('active')
    })
  })

  const [value, setValue] = useState(0)
  const [leftToken, setLeftToken] = useState(0)
  const [raisedToken, setRaisedToken] = useState(0)
  const [totalToken, setTotalToken] = useState(0)
  const [tokenPriceMap, setTokenPrices] = useState(new Map())

  return (
    <div className="user-dashboard">
      {/* <TopBar /> */}
      {/* Data feching */}
      <GetDetails
        setLeftToken={setLeftToken}
        setRaisedToken={setRaisedToken}
        setTotalToken={setTotalToken}
      />
      <SetPrice setTokenPrices={setTokenPrices} />

       <div className="user-wraper">
        <div class="container">
          <div class="d-flex">
            {/* <Sidebar /> */}
      <div className="user-content">
        <div className="user-panel">
          <div class="row">
            <div class="col-md-6">
              <div class="tile-item tile-primary">
                <div class="tile-bubbles"></div>

                <h6 class="tile-title">EXPLICIT TOKEN BALANCE</h6>
                {/* <h1 class="tile-info">120,000,000 IC0X</h1> */}
                <h1 class="tile-info" style={{ color: 'white' }}>
                  {leftToken} XPLCT
                </h1>

                {/* //? Custom... */}
                <h6 class="tile-title">EXPLICIT TOKEN PRICE</h6>
                <ul class="tile-list-inline">
                  <li>{tokenPriceMap.get('PAY_SOL')} SOL</li>
                  <li>{tokenPriceMap.get('PAY_RAY')} RAY</li>
                  <li>{tokenPriceMap.get('PAY_SRM')} SRM</li>
                  <li>{tokenPriceMap.get('PAY_USDC')} USDC</li>
                </ul>
              </div>
            </div>
            <div class="col-md-6">
              <div className='contribution-dash-main'>
                <div className='contribution-left-main'>
                <div class="tile-item tile-light contribution-inner contribution-padding">
                <h6 class="tile-title ">YOUR CONTRIBUTION</h6>
                <ul class="tile-info-list">
                  <li>
                    <span id="userTotalContrib">0</span>
                    <GetUserContrib/>
                  </li>
                </ul>
              </div>
                </div>
                <div className='contribution-left-main'>
                  <div className='contribution-top contribution-padding'>
                  <h6 class="tile-title text-contribution">Claim</h6>
                  <ul class="tile-info-list">
                  <li>
                    <span id="userTotalContrib">0</span>
                    <GetUserContrib/>
                  </li>
                </ul>
                  </div>
                  <div className='contribution-bottom'>
                  <button class="wallet-adapter-button wallet-adapter-button-trigger contribution-button" tabindex="0" type="button">Select Wallet</button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="info-card info-card-bordered">
            <div class="row align-items-center">
              <div class="col-sm-3">
                <div class="info-card-image">
                  <img src={interest} alt="vector" />
                </div>
                <div class="gaps-2x d-md-none"></div>
              </div>
              <div class="col-sm-9">
                <h4>
                Welcome to the XPLCT pre-selling. Thank you for participating.
                </h4>
                <p>
                You can purchase XPLCT tokens by connecting your Phantom wallet and going to the Contribution section.
                  
                </p>
                <p>
                You can get a quick response to any questions, and chat with the project in our Telegram:{' '}
                  <a href="https://t.me/+wFPDEUIuRK45OGYx">https://t.me/+wFPDEUIuRK45OGYx</a>
                </p>
                <p>
                Don't hesitate to invite your friends!
                </p>
              </div>
            </div>
          </div>

          {/* <div class="token-card"> */}
            {/* <div class="token-info">
              <span class="token-smartag">EXPLICIT Phase 2</span>
              <h2 class="token-bonus">
                20% <span>Current Bonus</span>
              </h2>
              <ul class="token-timeline">
                <li>
                  <span>START DATE</span>14 Jul 2022
                </li>
                <li>
                  <span>END DATE</span>25 Aug 2022
                </li>
              </ul>
            </div>
            <div class="token-countdown">
              <span class="token-countdown-title">THE BONUS END IN</span>
              <div class="token-countdown-clock" data-date="2022/09/05">
                <div class="col">
                  <span class="countdown-time dash-countdown countdown-time-first">
                    47
                  </span>
                  <span class="countdown-text dash-countdown">Days</span>
                </div>
                <div class="col">
                  <span class="countdown-time dash-countdown">09</span>
                  <span class="countdown-text dash-countdown">Hours</span>
                </div>
                <div class="col">
                  <span class="countdown-time countdown-time-last dash-countdown">
                    33
                  </span>
                  <span class="countdown-text dash-countdown">Minutes</span>
                </div>
              </div>
            </div> */}
          {/* </div> */}

          <div class="progress-card">
            <h4>Token Sale Progress</h4>
            <ul class="progress-info">
              <li>
                {/* <span>Raised -</span> 2,758 EXPLICIT */}
                <span>
                  Raised <b>{raisedToken}</b>
                </span>{' '}
                {leftToken} EXPLICIT
              </li>
              <li>
                {/* <span>TOTAL -</span> 1,500,000 EXPLICIT */}
                <span>
                  TOTAL <b>{totalToken}</b>
                </span>{' '}
                EXPLICIT
              </li>
            </ul>
            <div class="progress-bar-main">
              <RangeSlider
                value={((raisedToken / totalToken) * 100).toFixed(2)}
                onChange={(e) => setValue(e.target.value)}
                className="progress-baar-line"
              />
            </div>
          </div>

          <div class="gaps-3x"></div>
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th>Private Sale</th>
                  <th>First Round</th>
                  <th>Second Round</th>
                  <th>Final Round</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <span>Start Date</span>01 January 2023
                  </td>
                  <td>
                    <span>Start Date</span>01 April 2023
                  </td>
                  <td>
                    <span>Start Date</span>1 July 2023
                  </td>
                  <td>
                    <span>Start Date</span>1 October 2023
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>End Date</span>31 March 2023
                  </td>
                  <td>
                    <span>End Date</span>30 June 2023
                  </td>
                  <td>
                    <span>End Date</span>30 September 2023
                  </td>
                  <td>
                    <span>End Date</span>31 December 2023
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      </div>
      </div>
      </div>
      {/* </div>
      </div>
      </div> */}
      <div class="footer-bar">
        <div class="container">
          <div class="row">
            <div class="col-md-7">
              <span class="footer-copyright">
                Copyright 2022, <a href="#">Explicit</a>. All Rights Reserved.
              </span>
            </div>
            <div class="col-md-5 text-md-end">
              <ul class="footer-links">
                <li>
                  <a href="#">Privacy Policy</a>
                </li>
                <li>
                  <a href="#">Terms of Sales</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dashboard
