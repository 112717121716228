import xplct_idl from './xplct.json'
import { Buffer } from 'buffer'

import { clusterApiUrl } from '@solana/web3.js'
import * as anchor from '@project-serum/anchor'
import { web3, BorshCoder } from '@project-serum/anchor'

import { utf8 } from '@project-serum/anchor/dist/cjs/utils/bytes'
import React, { useEffect } from 'react'
require('@solana/wallet-adapter-react-ui/styles.css')
const network = 'https://api.devnet.solana.com'
const connection = new anchor.web3.Connection(network, 'confirmed')

export const SetPrice = (props) => {
  return <Content setTokenPrices={props.setTokenPrices} />
}
export default SetPrice

const Content = (props) => {
  window.Buffer = Buffer
  const a = JSON.stringify(xplct_idl)
  const b = JSON.parse(a)
  const program_id = new web3.PublicKey(xplct_idl.metadata.address.toString())
  let programAccount = null

  // it can be chaged.
  let token_id = new anchor.web3.PublicKey(
    'd55cgyF3QV32UWHUp3j2yvyDUz5ywg713C1Q6Adxmhj',
  )

  const seed1 = token_id.toBytes()
  // const seed2 = utf8.encode('ok9') //? need to change as main deployed  program seed.
  const seed2 = utf8.encode('ok1') //? need to change as main deployed  program seed.

  async function getAndSetAta() {
    let [programAccountPda, _] = await anchor.web3.PublicKey.findProgramAddress(
      [seed1, seed2],
      program_id,
    )

    programAccount = programAccountPda
  }

  const _setPrice = async () => {
    await getAndSetAta()
    let result_account = await connection.getAccountInfo(programAccount)
    let program_idl = new BorshCoder(xplct_idl)
    let result = program_idl.accounts.decode('PAccount', result_account.data)

    if (result === null) {
      console.log('Failed to get data may be wrong account you have passed')
      return null
    }

    const tokenD = 6
    const solD = 9
    const rayD = 9
    const srmD = 6
    const udscD = 6

    const solPrice = (
      result.solPrice.toNumber() / Math.pow(10, solD)
    ).toString()
    const rayPrice = (
      result.rayPrice.toNumber() / Math.pow(10, rayD)
    ).toString()
    const srmPrice = (
      result.srmPrice.toNumber() / Math.pow(10, srmD)
    ).toString()
    const usdcPrice = (
      result.usdcPrice.toNumber() / Math.pow(10, udscD)
    ).toString()

    let _map = new Map()
    _map.set('PAY_SOL', parseFloat(solPrice))
    _map.set('PAY_RAY', parseFloat(rayPrice))
    _map.set('PAY_SRM', parseFloat(srmPrice))
    _map.set('PAY_USDC', parseFloat(usdcPrice))
    props.setTokenPrices(_map)
  }

  useEffect(() => {
    _setPrice()
  }, [])

  return <></>
}
