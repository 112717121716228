import React, { useState } from "react";
import "../login/login.style.css";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

const Registration = () => {
  const navigate = useNavigate();
  const [username, setUserName] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();

  // Error states

  const [usernameError, setUsernameError] = useState();
  const [emailError, setEmailError] = useState();
  const [passwordError, setPasswordError] = useState();
  const [confirmPasswordError, setConfirmPasswordError] = useState();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!username) {
      setUsernameError("Please Enter Username");
    } else {
      setUsernameError("");
    }

    if (!email) {
      setEmailError("Please Enter email");
    } else {
      setEmailError("");
    }
    if (!password) {
      setPasswordError("Please Enter password");
    } else {
      setPasswordError("");
    }
    if (!confirmPassword) {
      setConfirmPasswordError("Please Enter confirmPassword");
    } else if (password !== confirmPassword) {
      setConfirmPasswordError("Password doesn't matched");
    } else {
      setConfirmPasswordError("");
    }

    if (
      username !== "" &&
      email !== "" &&
      password !== "" &&
      confirmPassword !== ""
    ) {
      let userData = new URLSearchParams();
      userData.append("userName", username);
      userData.append("email", email);
      userData.append("password", password);
      userData.append("confirmPassword", confirmPassword);

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: userData,
      };

      // fetch("http://localhost:3001/registerUser", requestOptions)
        fetch('https://pms.virtualheight.com/explicit/explicit/registerUser', requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data.status == "1") {
            navigate("/signin");
            toast.success(data.message);
          } else {
            toast.error(data.message);
          }
          console.log(data, "res");
        });
    }

    console.log(password, confirmPassword, "data");
  };

  return (
    <div className="login-container">
      <ToastContainer />
      <form className="signupform">
        <h4 className="form-title">Sign Up</h4>
        <div className="input-container">
          <label className="label">Username: </label>
          <input
            type="text"
            name="username"
            className="input"
            placeholder="Username"
            value={username}
            onChange={(e) => setUserName(e.target.value)}
          />
          <p style={{ color: "red" }}>{usernameError}</p>
        </div>
        <div className="input-container">
          <label className="label">Email: </label>
          <input
            type="text"
            name="email"
            className="input"
            placeholder="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <p style={{ color: "red" }}>{emailError}</p>
        </div>
        <div className="input-container">
          <label className="label">Password: </label>
          <input
            type="password"
            name="password"
            className="input"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <p style={{ color: "red" }}>{passwordError}</p>
        </div>
        <div className="input-container">
          <label className="label">Confirm Password: </label>
          <input
            type="password"
            name="confirmPassword"
            className="input"
            placeholder="confirm password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <p style={{ color: "red" }}>{confirmPasswordError}</p>
        </div>
        <button
          type="submit"
          id="login-btn"
          style={{ marginBottom: "2rem" }}
          onClick={handleSubmit}
        >
          Register
        </button>
        <a href="#" className="signuplink">
          <a href="/signin"> Already have an account? Sign In</a>
        </a>
      </form>
    </div>
  );
};

export default Registration;
