import React from 'react'
import TopBar from '../TopBar'
import Sidebar from '../Sidebar'

import { Outlet } from 'react-router-dom'
const Topcomponent = () => {
  return (
    <>
    <div className='main-dd'>
      <TopBar />
      <div className='user-wraper'>
        <div class="container">
          <div class="d-flex page-top-gap ">
            <Sidebar />
            <Outlet />
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default Topcomponent
