import logo from './logo.svg';
import './App.css';
import AppRoutes from './AppRoutes';
import { ToastContainer } from 'react-toastify';

function App() {
  return (
    <div className="App">
      <ToastContainer />
     <AppRoutes/>
    </div>
  );
}

export default App;
