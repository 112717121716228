import React, { useState } from 'react'
import $ from 'jquery'
import Navbar from '../container/Navbar'
import '../assets/css/style.css'
import '../assets/css/vendor.bundle.css'
import '../assets/css/style-salvia.css'
// import '../assets/css/vendor.bundle.bs4.css'
// import '../assets/css/vendor.bundle.css'
import '../assets/css/theme.css'
// import '../assets/css/style-dark.css'
import '../components/home.css'
import '../assets/css/responsive.css'
import logo from '../images/logo.png'
import logofullwhite from '../images/logo-full-white.png'
import block1 from '../images/home/gfx-n.png'
import block2 from '../images/home/gfx-m.png'
import block3 from '../images/home/gfx-sm-f.png'
import destileft from '../images/home/e-dark.png'
import distiright from '../images/home/f-dark.png'
import elogo from '../images/home/e-logo.png'
import laptop from '../images/home/laptop-mobile-a.png'
import plane from '../images/home/doodle_plane.gif'
import fondar1 from '../images/home/doodle_evan.png'
import team1 from '../images/home/team1.jpg'
import aboutimg from '../images/home/gfx-k.png'
import sc_large_q from '../images/app-screens/sc-large-q.png'
import sc_large_q2 from '../images/app-screens/sc-large-q2.png'
import gfx_b from '../images/gfx/gfx-b.png'
import icon_t2 from "../images/icons/icon-t2.png"
import collection from "../images/home/gfx-z-b.png"
import metaverse from "../images/home/metaverse.jpg"
import partner1 from "../images/home/p-1.png"
import partner2 from "../images/home/p-2.png"
import partner3 from "../images/home/p-3.png"
import partner4 from "../images/home/p-4.png"
import partner5 from "../images/home/p-5.png"
import p1 from "../images/home/p1.png"
import p2 from "../images/home/p2.png"
import p3 from "../images/home/p3.png"
import p4 from "../images/home/p4.png"
import p5 from "../images/home/p5.png"
import p6 from "../images/home/p6.png"
import p7 from "../images/home/p7.png"
import p8 from "../images/home/p8.png"
import p9 from "../images/home/p9.png"
import p10 from "../images/home/p10.png"
import blog1 from "../images/home/blog1.jpg"
import blog2 from "../images/home/blog2.jpg"
import blog3 from "../images/home/blog3.jpg"
import instagram from "../images/home/instagram.png"
import twitter from "../images/home/twitter.png"
import telegram from "../images/home/telegram.png"
import game from "../images/home/game.png"
import footer from '../images/home/footer.png'
import t1 from "../images/home/t1.png"
import t2 from "../images/home/t2.png"
import t3 from "../images/home/t3.png"
import banneright from "../images/home/banner.png"
import Footer from '../container/Footer'
// import "animate.css/animate.min.css";
import ScrollAnimation from 'react-animate-on-scroll';
import OwlCarousel from 'react-owl-carousel3';

const Home = () => {
  const [ourusers, setourusers] = useState({
    ourusers: {
      0: {
        items: 2,
      },
      768: {
        items: 3,
      },
      1024: {
        items: 4,
      },
      1200: {
        items: 5,
      }
    }
  })

  const [owlteam1, setowlteam1] = useState({
    owlteam1: {
      0: {
        items: 2,
      },
      768: {
        items: 3,
      },
      1024: {
        items: 3,
      },
      1200: {
        items: 3,
      }
    }
  })

  return (
    <div className='nk-body body-wider mode-onepage'>
      <div className="nk-wrap">
        {/* <header className="nk-header page-header is-transparent is-sticky is-shrink is-split has-fixed" id="header"></header> */}
        <Navbar />

        <section className='banner-sec header-banner bg-theme-dark has-ovm has-mask '>
          <div class="nk-banner">
            <div class="banner banner-mask-fix banner-fs banner-single tc-light ">
              <div class="banner-wrap ov-v">
                <div class="container">
                  <div class="row align-items-center justify-content-center justify-content-lg-between gutter-vr-30px">
                    <div class="col-lg-6">
                      <div class="banner-caption wide-auto text-center text-lg-start banner-left">
                        <div class="cpn-head mt-0">
                          <h3 class="banner-title" data-animate="fadeInUp" data-delay="1.25">Global Decentralized Platform Designed</h3>
                          <h3 class="banner-title " data-animate="fadeInUp" data-delay="1.25">To Buy & Sell Adult Content & Services</h3>
                        </div>
                        <p class="lead banner-para" data-animate="fadeInUp" data-delay="1.35">Brining Blackchain Technology into the Adult Industry</p>
                        <div class="cpn-btns">
                          <ul class="btn-grp " data-animate="fadeInUp" data-delay="1.45">
                            <li><a href="/signup" class="btn btn-md btn-grad btn-round banner-btn">REGISTER TO BUY</a></li>
                            {/* <li><a href="#" class="btn btn-md btn-grad btn-grad-alternet btn-round banner-btn">Token Distribution</a></li> */}
                          </ul>
                        </div>
                        <ul class="team-social team-social-s2 banner-social-media ">
                          <li><a href="https://t.me/+wFPDEUIuRK45OGYx"><img src={telegram} className="social-img"></img></a></li>
                          <li><a href="#"><img src={game} className="social-img"></img></a></li>
                          <li><a href="#"><img src={twitter} className="social-img"></img></a></li>
                          <li><a href="#"><img src={instagram} className="social-img"></img></a></li>
                        </ul>
                      </div>
                    </div>
                    <div class="col-lg-5 col-sm-9">
                      <div className='banner-right'>
                        <div class="new-banner"></div>
                        <div class="token-status token-status-s5 bg-transparent no-bd banner-timer">
                          {/* <div class="nk-circle-pk animated" data-animate="fadeIn" data-delay="1.75">
                                                    <div class="line-1"></div>
                                                    <div class="line-2"></div>
                                                    <div class="line-3"></div>
                                                    <div class="line-4"></div>
                                                </div> */}

                          <div class="token-box token-box-s3 inner-timer-banner" data-animate="fadeInUp" data-delay="1.85">
                            <div class="countdown-s3 countdown-s4 countdown" data-date="2022/06/11">
                              <div class="countdown-item">
                                <span class="countdown-time countdown-time-first timer-text">38</span>
                                <span class="countdown-text timer-text">Days</span>
                              </div>
                              <div class="countdown-item">
                                <span class="countdown-time">12</span>
                                <span class="countdown-text">Hours</span>
                              </div>
                              <div class="countdown-item">
                                <span class="countdown-time">46</span>
                                <span class="countdown-text">Min</span>
                              </div>
                              <div class="countdown-item">
                                <span class="countdown-time countdown-time-last">32</span>
                                <span class="countdown-text">Sec</span>
                              </div>
                            </div>
                          </div>
                          <div class="token-action " data-animate="fadeInUp" data-delay="1.85">
                            <a class="btn btn-rg btn-grad btn-grad-alternet btn-round banner-btn" href="/signup">REGISTER &amp; Buy Token</a>
                          </div>
                          <ul class="icon-list animated">
                            <li><img src={t1}></img></li>
                            <li><img src={t2}></img></li>
                            <li><img src={t3}></img></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="bloackchain-sec" id='platform'>
          <div class="container">
            <div class="nk-block nk-block-features-s2">
              <div class="row flex-row-reverse gutter-vr-30px">
                <div class="col-md-6">
                  <ScrollAnimation animateIn="fadeInUp" delay="0.5">
                    <div class="gfx animated fadeInUp" >
                      <img className='block-img' src={laptop} alt="logo" />
                    </div>
                  </ScrollAnimation>
                </div>
                <div class="col-md-6">
                  <ScrollAnimation animateIn="fadeInUp" delay="0.5">
                    <div class="nk-block-text animated fadeInUp">
                      <div className='main-title-box mt-50'>
                        <h1 className='main-title-text'>Explicit Platform</h1>
                      </div>
                      <p class="block-para" >toward adults through the explicit platform. Explicit coins are used by the entertainers to pay for the services and materials, and there are no restrictions on adult content and services.</p>
                    </div>
                  </ScrollAnimation>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="bloackchain-sec" id='wallet'>
          <div class="container">
            <div class="nk-block-features-s2">
              <div class="row align-items-center gutter-vr-30px mt-100" >
                <div class="col-md-6">
                  <ScrollAnimation animateIn="fadeInUp" delay="0.5">
                    <div class="gfx animated fadeInUp">
                      <img className='block-img animated fadeInUp' src={block2} alt="logo" />
                    </div>
                  </ScrollAnimation>
                </div>
                <div class="col-md-6">
                  <ScrollAnimation animateIn="fadeInUp" delay="0.5">
                    <div class="nk-block-text animated fadeInUp">
                      <div className='row'>
                        <div className='main-title-box'>
                          {/* <h3 className='main-subtitle-text'>Global Community</h3> */}
                          <h1 className='main-title-text'>Explicit Wallet</h1>
                        </div>
                      </div>
                      <p class="block-para" >The private keys are stored in the specified wallet, which ensures the currency's security. The Explicit coin is the new standard crypto payment method for the sex industry's booming market. Professionals in the entertainment industry may keep 99 percent of their revenues while preserving the privacy of their customers and their own identities with the Explicit Wallet</p>
                    </div>
                  </ScrollAnimation>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className='metaverse-sec' id='metaverse'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-sm-12 col-md-12 col-lg-12'>
                <ScrollAnimation animateIn="fadeInUp" delay="0.5">
                  <div className='meta-content'>
                    <h1 className='meta-title animated fadeInUp'>Explicit Metaverse</h1>
                    <p className='meta-para animated fadeInUp'>
                      The Explicit Metaverse is a completely decentralised online environment that features a world that is accessible to anyone. Users are the ones who own and control the explicit metaverse. The Explicit Metaverse will be the first "virtual reality red-light district" in the world, and it will be a place where viewers can meet all of their preferred content creators. Users have the option to acquire real estate, build entire street blocks, create their own entertainment by renting out sections, contribute material in the form of entertainers, or simply explore the virtual world and pay their favourite entertainers a visit.
                    </p>
                  </div>
                </ScrollAnimation>
              </div>
              <div className='col-sm-12 col-md-12 col-lg-12'>
                <ScrollAnimation animateIn="fadeInUp" delay="0.5">
                  <div className='metavers-img-box animated fadeInUp'>
                    <img className='metavers-img' src={metaverse} alt="logo" />
                  </div>
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </section>

        <section className='token-sale-main' id='token'>
          <ScrollAnimation animateIn="fadeInUp" delay="0.5">
            <div className='container'>
              <div className='row'>
                <div className='main-title-box'>
                  <h3 className='main-subtitle-text animated fadeInUp'>Token</h3>
                  <h1 className='main-title-text animated fadeInUp'>Token Sale</h1>
                  <p className='token-para animated fadeInUp'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. <br /> Mauris in hendrerit nisl.</p>
                </div>
              </div>
              <div className='row'>
                <div className='col-sm-8 col-md-12 col-lg-8'>
                  <div className='row'>
                    <div className='col-sm-4 col-md-6 col-lg-4'>
                      <div className='token-pre-sale-box'>
                        <div className='pre-sale-inner-top'>
                          <h1 className='pre-sale-text animated fadeInUp'>Private Sale</h1>
                        </div>
                        <div className='pre-sale-inner-middle'>
                          <h3 className='pre-sale-date animated fadeInUp'>01 January 2023</h3>
                          <h5 className='pre-sale-days animated fadeInUp'>90 Days</h5>
                        </div>
                        {/* <div className='pre-sale-inner-bottom'>
                          <h3 className='pre-sale-bonus-text animated fadeInUp'>30% Bonus</h3>
                          <h5 className='pre-sale-soft-text animated fadeInUp'>Soft Cap</h5>
                          <h5 className='pre-sale-price animated fadeInUp'>$2M</h5>
                        </div> */}
                      </div>
                    </div>
                    <div className='col-sm-4 col-md-6 col-lg-4'>
                      <div className='token-pre-sale-box'>
                        <div className='pre-sale-inner-top'>
                          <h1 className='pre-sale-text animated fadeInUp'>First Round</h1>
                        </div>
                        <div className='pre-sale-inner-middle'>
                          <h3 className='pre-sale-date animated fadeInUp'>01 April 2023</h3>
                          <h5 className='pre-sale-days animated fadeInUp'>60 Days</h5>
                        </div>
                        {/* <div className='pre-sale-inner-bottom'>
                          <h3 className='pre-sale-bonus-text animated fadeInUp'>20% Bonus</h3>
                          <h5 className='pre-sale-soft-text animated fadeInUp'>Hard Cap</h5>
                          <h5 className='pre-sale-price animated fadeInUp'>$5M</h5>
                        </div> */}
                      </div>
                    </div>
                    <div className='col-sm-4 col-md-6 col-lg-4 mdtop-20px'>
                      <div className='token-pre-sale-box'>
                        <div className='pre-sale-inner-top'>
                          <h1 className='pre-sale-text animated fadeInUp'>Second Round</h1>
                        </div>
                        <div className='pre-sale-inner-middle'>
                          <h3 className='pre-sale-date animated fadeInUp'>30 July 2023</h3>
                          <h5 className='pre-sale-days animated fadeInUp'>90 Days</h5>
                        </div>
                        {/* <div className='pre-sale-inner-bottom'>
                          <h3 className='pre-sale-bonus-text animated fadeInUp'>10% Bonus</h3>
                          <h5 className='pre-sale-soft-text animated fadeInUp'>Hard Cap</h5>
                          <h5 className='pre-sale-price animated fadeInUp'>$3M</h5>
                        </div> */}
                      </div>
                    </div>
                    <div className='col-sm-12 col-md-12 col-lg-12'>
                      <div className='token-pre-join-main'>
                        <div className='token-join-box'>
                          <h2 className='animated fadeInUp'>Join Our <br />Pre-Sale List</h2></div>
                        <div className='token-sign-up-btns'>
                          <h2 className='animated fadeInUp'>Pre Sale Start at <br /><span className='join-date-text'>03</span> January 2023</h2>
                          <div className='token-btn-box'>
                            <a href="#" class="btn token-btn btn-md btn-grad btn-round btn-auto">Sign Up & Join</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-sm-4 col-md-12 col-lg-4'>
                  <div className='token-table'>
                    <table class="table table-bordered">
                      <tbody>
                        <tr className='bg-light-grey'>
                          <td className='taken-td-odd animated fadeInUp'>Token Symbol</td>
                          <td className='taken-td-odd animated fadeInUp'><img src={elogo}></img></td>
                        </tr>
                        <tr class="bg-light-blue">
                          <td className='long-td token-td-even animated fadeInUp'>Tiken Sale Start</td>
                          <td className='long-td token-td-even animated fadeInUp'>01 January 2023</td>
                        </tr>
                        <tr class="bg-light-grey">
                          <td className='taken-td-odd animated fadeInUp'>Token Sale End</td>
                          <td className='taken-td-odd animated fadeInUp'>30 September 2023</td>
                        </tr>
                        <tr class="bg-light-blue">
                          <td className='token-td-even animated fadeInUp'>Tokens for sale </td>
                          <td className='token-td-even animated fadeInUp'>100,500,000</td>
                        </tr>
                        <tr className='bg-light-grey'>
                          <td className='taken-td-odd animated fadeInUp'>Token Price</td>
                          <td className='taken-td-odd animated fadeInUp'>$0.80 USD</td>
                        </tr>
                        <tr class="bg-light-blue">
                          <td className='long-td token-td-even animated fadeInUp'>Specifications</td>
                          <td className='long-td token-td-even animated fadeInUp'>ICOX token</td>
                        </tr>
                        <tr class="bg-light-grey">
                          <td className='taken-td-odd animated fadeInUp'>Max circulating supply</td>
                          <td className='taken-td-odd animated fadeInUp'>175,500,000</td>
                        </tr>
                        <tr class="bg-light-blue">
                          <td className='token-td-even animated fadeInUp'>Sale duration</td>
                          <td className='token-td-even animated fadeInUp'>60 days</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </ScrollAnimation>
        </section>

        <div class="distibute">
          <div className='container'>
            <div class="row justify-content-center gutter-vr-40px">
              {/* <div className='col-sm-12 col-md-12 col-lg-12'>
                                <h4 className='roadmap-middle-para text-center'>**Note:Follow token distribution and fund allocation on Explicit Whitepaper**</h4>
                            </div> */}
              <div class="col-md-6 col-mb-8">
                <ScrollAnimation animateIn="fadeInUp" delay="0.5">
                  <div class="single-chart">
                    <h3 class="mgb-l text-center title-lg animated fadeInUp distibute-title" data-animate="fadeInUp">Tokens<br />Distribution</h3>
                    <div class="chart animated fadeInUp">
                      <img className='disti-left-img animated fadeInUp' src={destileft} alt="logo" />
                    </div>
                  </div>
                </ScrollAnimation>
              </div>
              <div class="col-md-6 col-mb-8">
                <ScrollAnimation animateIn="fadeInUp" delay="0.5">
                  <div class="single-chart">
                    <h3 class="mgb-l text-center title-lg animated fadeInUp distibute-title" data-animate="fadeInUp">Fund<br />Allocation</h3>
                    <div class="chart animated fadeInUp">
                      <img className='disti-left-img animated fadeInUp' src={distiright} alt="logo" />
                    </div>
                  </div>
                </ScrollAnimation>
              </div>
            </div>
          </div>

        </div>


        <section class="roadmap-sec bg-theme  section-connect" id="roadmap">
          <div class="container ov-h">
            <div class="section-head text-center">
              <ScrollAnimation animateIn="fadeInUp" delay="0.5">
                <h2 class="title title-s4 animated fadeInUp" title='Roadmap'>Our Roadmap</h2>
              </ScrollAnimation>
            </div>
          </div>

          <div class="nk-block ov-h">
            <div class="container">
              <ScrollAnimation animateIn="fadeInUp" delay="0.5">
                <div class="roadmap-list animated fadeInUp" >
                  <div class="roadmap-item roadmap-item-sm roadmap-done">
                    <div class="roadmap-innr">
                      <h6 class="roadmap-title roadmap-title-s2">July 2022</h6>
                      <p className='roadmap-para'>Explicit ICO Website </p>
                    </div>
                  </div>
                  <div class="roadmap-item roadmap-done">
                    <div class="roadmap-innr">
                      <h6 class="roadmap-title roadmap-title-s2">October 2022 </h6>
                      <p className='roadmap-para'>Token Smart Contract </p>
                    </div>
                  </div>
                  <div class="roadmap-item roadmap-item-lg">
                    <div class="roadmap-innr">
                      <h6 class="roadmap-title roadmap-title-s2">Q2 2023</h6>
                      <p className='roadmap-para'>Explicit Token Management Website </p>
                    </div>
                  </div>
                  <div class="roadmap-item">
                    <div class="roadmap-innr">
                      <h6 class="roadmap-title roadmap-title-s2">Q3 2023</h6>
                      <p className='roadmap-para'>Explicit Application Private Beta </p>
                    </div>
                  </div>
                  <div class="roadmap-item roadmap-item-sm">
                    <div class="roadmap-innr">
                      <h6 class="roadmap-title roadmap-title-s2">Q3 2023</h6>
                      <p className='roadmap-para'>Explicit Wallet Launch</p>
                    </div>
                  </div>
                  <div class="roadmap-item">
                    <div class="roadmap-innr">
                      <h6 class="roadmap-title roadmap-title-s2">Q4 2023</h6>
                      <p className='roadmap-para'>Explicit Application Launch </p>
                    </div>
                  </div>
                  <div class="roadmap-item roadmap-item-lg">
                    <div class="roadmap-innr">
                      <h6 class="roadmap-title roadmap-title-s2">Q3 2024</h6>
                      <p className='roadmap-para'>Explicit Metaverse Private Beta </p>
                    </div>
                  </div>
                  <div class="roadmap-item">
                    <div class="roadmap-innr">
                      <h6 class="roadmap-title roadmap-title-s2">Q4 2024</h6>
                      <p className='roadmap-para'>Explicit Metaverse Launch </p>
                    </div>
                  </div>
                  {/* <div class="roadmap-item roadmap-item-sm">
                                    <div class="roadmap-innr">
                                        <h6 class="roadmap-title roadmap-title-s2">March 2023</h6>
                                        <p className='roadmap-para'>Start Token Sale Round (2)</p>
                                    </div>
                                </div>
                                <div class="roadmap-item">
                                    <div class="roadmap-innr">
                                        <h6 class="roadmap-title roadmap-title-s2">April 2023</h6>
                                        <p className='roadmap-para'>Blockchain usage announcement for global Network</p>
                                    </div>
                                </div>
                                <div class="roadmap-item roadmap-item-lg">
                                    <div class="roadmap-innr">
                                        <h6 class="roadmap-title roadmap-title-s2">May 2023</h6>
                                        <p className='roadmap-para'>Operational Launch United States to general</p>
                                    </div>
                                </div> */}
                </div>
              </ScrollAnimation>
            </div>
          </div>
        </section>


        <section className='foundars-sec' id='team'>
          <div className='container'>
            <div className='row'>
              <div className='col-sm-12 col-md-12 col-lg-12'>
                <ScrollAnimation animateIn="fadeInUp" delay="0.5">
                  <div className='partner-content'>
                    <h1 className='foundar-inner-title animated fadeInUp'>Founder</h1>
                    <div className='partner-para animated fadeInUp'>The explicit team has a solid workforce of 20+ members that includes fantastic artists, innovative developers, and creative designers. Most of them are from this community and are selected based on their decision-making ability, crypto enthusiasm, and integrity.</div>
                  </div>
                </ScrollAnimation>
              </div>
            </div>
            <div className='owl-row'>
              <OwlCarousel
                className="owl-theme team-owl"
                loop={false} margin={2} item={3} dot={false} nav={true} navText={["<", ">"]} responsive={owlteam1.owlteam1}
              >
                <div class="item">
                  <ScrollAnimation animateIn="fadeInUp" delay="0.5">
                    <div className='foundar-box'>
                      <div className='foundar-img-box animated fadeInUp'>
                        <img class="logo-dark" src={team1} alt="logo" />
                      </div>

                      <div className='foundar-details'>
                        <h1 className='fondar-title team-color animated fadeInUp'>Louis Baker</h1>
                        <h5 className='foundar-position animated fadeInUp'> CEO & Load Blockchain</h5>
                        <ul class="team-social team-social-s2 team-social-media animated fadeInUp">
                          <li><a href="#"><img src={twitter} className="social-img"></img></a></li>
                          <li><a href="#"><img src={instagram} className="social-img"></img></a></li>
                        </ul>
                      </div>
                    </div>
                  </ScrollAnimation>
                </div>
                <div class="item">
                  <ScrollAnimation animateIn="fadeInUp" delay="0.5">
                    <div className='foundar-box'>
                      <div className='foundar-img-box animated fadeInUp'>
                        <img class="logo-dark" src={team1} alt="logo" />
                      </div>

                      <div className='foundar-details'>
                        <h1 className='fondar-title animated fadeInUp'>Jonathan Rios</h1>
                        <h5 className='foundar-position team-color animated fadeInUp'>CTO & Software Engineer</h5>
                        <ul class="team-social team-social-s2 team-social-media animated fadeInUp">
                          <li><a href="#"><img src={twitter} className="social-img"></img></a></li>
                          <li><a href="#"><img src={instagram} className="social-img"></img></a></li>
                        </ul>
                      </div>
                    </div>
                  </ScrollAnimation>
                </div>
                <div class="item">
                  <ScrollAnimation animateIn="fadeInUp" delay="0.5">
                    <div className='foundar-box'>
                      <div className='foundar-img-box animated fadeInUp'>
                        <img class="logo-dark" src={team1} alt="logo" />
                      </div>

                      <div className='foundar-details'>
                        <h1 className='fondar-title animated fadeInUp'>Jason Morales </h1>
                        <h5 className='foundar-position team-color animated fadeInUp'> Sr,Backend developer</h5>
                        <ul class="team-social team-social-s2 team-social-media animated fadeInUp">
                          <li><a href="#"><img src={twitter} className="social-img"></img></a></li>
                          <li><a href="#"><img src={instagram} className="social-img"></img></a></li>
                        </ul>
                      </div>
                    </div>
                  </ScrollAnimation>
                </div>
                <div class="item">
                  <ScrollAnimation animateIn="fadeInUp" delay="0.5">
                    <div className='foundar-box'>
                      <div className='foundar-img-box animated fadeInUp'>
                        <img class="logo-dark" src={team1} alt="logo" />
                      </div>

                      <div className='foundar-details'>
                        <h1 className='fondar-title team-color animated fadeInUp'>Louis Baker</h1>
                        <h5 className='foundar-position animated fadeInUp'> CEO & Load Blockchain</h5>
                        <ul class="team-social team-social-s2 team-social-media animated fadeInUp">
                          <li><a href="#"><img src={twitter} className="social-img"></img></a></li>
                          <li><a href="#"><img src={instagram} className="social-img"></img></a></li>
                        </ul>
                      </div>
                    </div>
                  </ScrollAnimation>
                </div>

              </OwlCarousel>
            </div>
          </div>

          <div className='container'>
            <div className='row'>
              <div className='col-sm-12 col-md-12 col-lg-12'>
                <ScrollAnimation animateIn="fadeInUp" delay="0.5">
                  <h1 className='foundar-inner-title animated fadeInUp'>Advisores</h1>
                </ScrollAnimation>
              </div>
            </div>
            <OwlCarousel
              className="owl-theme team-owl"
              loop={false} margin={2} item={2} dot={false} nav={true} navText={["<", ">"]} responsive={ourusers.ourusers}
            >
              <div class="item">
                <ScrollAnimation animateIn="fadeInUp" delay="0.5">
                  <div className='foundar-box'>
                    <div className='foundar-img-box animated fadeInUp'>
                      <img class="logo-dark" src={team1} alt="logo" />
                    </div>

                    <div className='foundar-details'>
                      <h1 className='fondar-title team-color animated fadeInUp'>Louis Baker</h1>
                      <h5 className='foundar-position animated fadeInUp'> CEO & Load Blockchain</h5>
                      <ul class="team-social team-social-s2 team-social-media animated fadeInUp">
                        <li><a href="#"><img src={twitter} className="social-img"></img></a></li>
                        <li><a href="#"><img src={instagram} className="social-img"></img></a></li>
                      </ul>
                    </div>
                  </div>
                </ScrollAnimation>
              </div>
              <div class="item">
                <ScrollAnimation animateIn="fadeInUp" delay="0.5">
                  <div className='foundar-box'>
                    <div className='foundar-img-box animated fadeInUp'>
                      <img class="logo-dark" src={team1} alt="logo" />
                    </div>

                    <div className='foundar-details'>
                      <h1 className='fondar-title animated fadeInUp'>Jonathan Rios</h1>
                      <h5 className='foundar-position team-color animated fadeInUp'>CTO & Software Engineer</h5>
                      <ul class="team-social team-social-s2 team-social-media animated fadeInUp">
                        <li><a href="#"><img src={twitter} className="social-img"></img></a></li>
                        <li><a href="#"><img src={instagram} className="social-img"></img></a></li>
                      </ul>
                    </div>
                  </div>
                </ScrollAnimation>
              </div>
              <div class="item">
                <ScrollAnimation animateIn="fadeInUp" delay="0.5">
                  <div className='foundar-box'>
                    <div className='foundar-img-box animated fadeInUp'>
                      <img class="logo-dark" src={team1} alt="logo" />
                    </div>

                    <div className='foundar-details'>
                      <h1 className='fondar-title animated fadeInUp'>Jason Morales </h1>
                      <h5 className='foundar-position team-color animated fadeInUp'> Sr,Backend developer</h5>
                      <ul class="team-social team-social-s2 team-social-media animated fadeInUp">
                        <li><a href="#"><img src={twitter} className="social-img"></img></a></li>
                        <li><a href="#"><img src={instagram} className="social-img"></img></a></li>
                      </ul>
                    </div>
                  </div>
                </ScrollAnimation>
              </div>
              <div class="item">
                <ScrollAnimation animateIn="fadeInUp" delay="0.5">
                  <div className='foundar-box'>
                    <div className='foundar-img-box animated fadeInUp'>
                      <img class="logo-dark" src={team1} alt="logo" />
                    </div>

                    <div className='foundar-details'>
                      <h1 className='fondar-title team-color animated fadeInUp'>Louis Baker</h1>
                      <h5 className='foundar-position animated fadeInUp'> CEO & Load Blockchain</h5>
                      <ul class="team-social team-social-s2 team-social-media animated fadeInUp">
                        <li><a href="#"><img src={twitter} className="social-img"></img></a></li>
                        <li><a href="#"><img src={instagram} className="social-img"></img></a></li>
                      </ul>
                    </div>
                  </div>
                </ScrollAnimation>
              </div>
              <div class="item">
                <ScrollAnimation animateIn="fadeInUp" delay="0.5">
                  <div className='foundar-box'>
                    <div className='foundar-img-box animated fadeInUp'>
                      <img class="logo-dark" src={team1} alt="logo" />
                    </div>

                    <div className='foundar-details'>
                      <h1 className='fondar-title animated fadeInUp'>Jonathan Rios</h1>
                      <h5 className='foundar-position team-color animated fadeInUp'>CTO & Software Engineer</h5>
                      <ul class="team-social team-social-s2 team-social-media animated fadeInUp">
                        <li><a href="#"><img src={twitter} className="social-img"></img></a></li>
                        <li><a href="#"><img src={instagram} className="social-img"></img></a></li>
                      </ul>
                    </div>
                  </div>
                </ScrollAnimation>
              </div>
              <div class="item">
                <ScrollAnimation animateIn="fadeInUp" delay="0.5">
                  <div className='foundar-box'>
                    <div className='foundar-img-box animated fadeInUp'>
                      <img class="logo-dark" src={team1} alt="logo" />
                    </div>

                    <div className='foundar-details'>
                      <h1 className='fondar-title animated fadeInUp'>Jason Morales </h1>
                      <h5 className='foundar-position team-color animated fadeInUp'> Sr,Backend developer</h5>
                      <ul class="team-social team-social-s2 team-social-media animated fadeInUp">
                        <li><a href="#"><img src={twitter} className="social-img"></img></a></li>
                        <li><a href="#"><img src={instagram} className="social-img"></img></a></li>
                      </ul>
                    </div>
                  </div>
                </ScrollAnimation>
              </div>
            </OwlCarousel>
          </div>

          <div className='container'>
            <div className='row'>
              <div className='col-sm-12 col-md-12 col-lg-12'>
                <ScrollAnimation animateIn="fadeInUp" delay="0.5">
                  <h1 className='foundar-inner-title animated fadeInUp'>Ambassador</h1>
                </ScrollAnimation>
              </div>
            </div>
            <OwlCarousel
              className="owl-theme team-owl"
              loop={false} margin={2} item={2} dot={false} nav={true} navText={["<", ">"]} responsive={ourusers.ourusers}
            >
              <div class="item">
                <ScrollAnimation animateIn="fadeInUp" delay="0.5">
                  <div className='foundar-box'>
                    <div className='foundar-img-box animated fadeInUp'>
                      <img class="logo-dark" src={team1} alt="logo" />
                    </div>

                    <div className='foundar-details'>
                      <h1 className='fondar-title animated fadeInUp'>Rayan Rich</h1>
                      <h5 className='foundar-position team-color animated fadeInUp'>Operations & Technology</h5>
                      <ul class="team-social team-social-s2 team-social-media animated fadeInUp">
                        <li><a href="#"><img src={twitter} className="social-img"></img></a></li>
                        <li><a href="#"><img src={instagram} className="social-img"></img></a></li>
                      </ul>
                    </div>
                  </div>
                </ScrollAnimation>
              </div>
              <div class="item">
                <ScrollAnimation animateIn="fadeInUp" delay="0.5">
                  <div className='foundar-box'>
                    <div className='foundar-img-box animated fadeInUp'>
                      <img class="logo-dark" src={team1} alt="logo" />
                    </div>

                    <div className='foundar-details'>
                      <h1 className='fondar-title animated fadeInUp'>Brandon Casto</h1>
                      <h5 className='foundar-position team-color animated fadeInUp'>Bussiness Develolepment</h5>
                      <ul class="team-social team-social-s2 team-social-media animated fadeInUp">
                        <li><a href="#"><img src={twitter} className="social-img"></img></a></li>
                        <li><a href="#"><img src={instagram} className="social-img"></img></a></li>
                      </ul>
                    </div>
                  </div>
                </ScrollAnimation>
              </div>
              <div class="item">
                <ScrollAnimation animateIn="fadeInUp" delay="0.5">
                  <div className='foundar-box'>
                    <div className='foundar-img-box animated fadeInUp'>
                      <img class="logo-dark" src={team1} alt="logo" />
                    </div>

                    <div className='foundar-details'>
                      <h1 className='fondar-title animated fadeInUp'>Louis Baker</h1>
                      <h5 className='foundar-position team-color animated fadeInUp'>CEO & Lead Blockchain</h5>
                      <ul class="team-social team-social-s2 team-social-media animated fadeInUp">
                        <li><a href="#"><img src={twitter} className="social-img"></img></a></li>
                        <li><a href="#"><img src={instagram} className="social-img"></img></a></li>
                      </ul>
                    </div>
                  </div>
                </ScrollAnimation>
              </div>
              <div class="item">
                <ScrollAnimation animateIn="fadeInUp" delay="0.5">
                  <div className='foundar-box'>
                    <div className='foundar-img-box animated fadeInUp'>
                      <img class="logo-dark" src={team1} alt="logo" />
                    </div>

                    <div className='foundar-details'>
                      <h1 className='fondar-title animated fadeInUp'>Jonathan Rios</h1>
                      <h5 className='foundar-position team-color animated fadeInUp'>CTO & Software Engineer</h5>
                      <ul class="team-social team-social-s2 team-social-media animated fadeInUp">
                        <li><a href="#"><img src={twitter} className="social-img"></img></a></li>
                        <li><a href="#"><img src={instagram} className="social-img"></img></a></li>
                      </ul>
                    </div>
                  </div>
                </ScrollAnimation>
              </div>
              <div class="item">
                <ScrollAnimation animateIn="fadeInUp" delay="0.5">
                  <div className='foundar-box'>
                    <div className='foundar-img-box animated fadeInUp'>
                      <img class="logo-dark" src={team1} alt="logo" />
                    </div>

                    <div className='foundar-details'>
                      <h1 className='fondar-title animated fadeInUp'>Jason Morales</h1>
                      <h5 className='foundar-position team-color animated fadeInUp'>Sr.Backend developer</h5>
                      <ul class="team-social team-social-s2 team-social-media animated fadeInUp">
                        <li><a href="#"><img src={twitter} className="social-img"></img></a></li>
                        <li><a href="#"><img src={instagram} className="social-img"></img></a></li>
                      </ul>
                    </div>
                  </div>
                </ScrollAnimation>
              </div>
              <div class="item">
                <ScrollAnimation animateIn="fadeInUp" delay="0.5">
                  <div className='foundar-box'>
                    <div className='foundar-img-box animated fadeInUp'>
                      <img class="logo-dark" src={team1} alt="logo" />
                    </div>

                    <div className='foundar-details'>
                      <h1 className='fondar-title animated fadeInUp'>Jason Morales</h1>
                      <h5 className='foundar-position team-color animated fadeInUp'>Sr.Backend developer</h5>
                      <ul class="team-social team-social-s2 team-social-media animated fadeInUp">
                        <li><a href="#"><img src={twitter} className="social-img"></img></a></li>
                        <li><a href="#"><img src={instagram} className="social-img"></img></a></li>
                      </ul>
                    </div>
                  </div>
                </ScrollAnimation>
              </div>
              <div class="item">
                <ScrollAnimation animateIn="fadeInUp" delay="0.5">
                  <div className='foundar-box'>
                    <div className='foundar-img-box animated fadeInUp'>
                      <img class="logo-dark" src={team1} alt="logo" />
                    </div>

                    <div className='foundar-details'>
                      <h1 className='fondar-title animated fadeInUp'>Jason Morales</h1>
                      <h5 className='foundar-position team-color animated fadeInUp'>Sr.Backend developer</h5>
                      <ul class="team-social team-social-s2 team-social-media animated fadeInUp">
                        <li><a href="#"><img src={twitter} className="social-img"></img></a></li>
                        <li><a href="#"><img src={instagram} className="social-img"></img></a></li>
                      </ul>
                    </div>
                  </div>
                </ScrollAnimation>
              </div>
              <div class="item">
                <ScrollAnimation animateIn="fadeInUp" delay="0.5">
                  <div className='foundar-box'>
                    <div className='foundar-img-box animated fadeInUp'>
                      <img class="logo-dark" src={team1} alt="logo" />
                    </div>

                    <div className='foundar-details'>
                      <h1 className='fondar-title animated fadeInUp'>Jason Morales</h1>
                      <h5 className='foundar-position team-color animated fadeInUp'>Sr.Backend developer</h5>
                      <ul class="team-social team-social-s2 team-social-media animated fadeInUp">
                        <li><a href="#"><img src={twitter} className="social-img"></img></a></li>
                        <li><a href="#"><img src={instagram} className="social-img"></img></a></li>
                      </ul>
                    </div>
                  </div>
                </ScrollAnimation>
              </div>
            </OwlCarousel>
          </div>


        </section>

        <section class="partner-sec" id="partners">
          <div class="container">
            <div class="section-head text-center wide-auto-sm">
              <ScrollAnimation animateIn="fadeInUp" delay="0.5">
                <h2 class="title title-s4 animated fadeInUp" >ICO Crypto Partners</h2>
              </ScrollAnimation>
            </div>
            <div class="nk-block block-partners">
              <ScrollAnimation animateIn="fadeInUp" delay="0.5">
                <ul class="partner-list flex-wrap">
                  <li class="partner-logo-lg animated fadeInUp"><img className='partner-img' src={partner1} alt="logo" /></li>
                  <li class="partner-logo-lg animated fadeInUp"><img className='partner-img' src={partner2} alt="logo" /></li>
                  <li class="partner-logo-lg animated fadeInUp"><img className='partner-img' src={partner3} alt="logo" /></li>
                  <li class="partner-logo-lg animated fadeInUp"><img className='partner-img' src={partner4} alt="logo" /></li>
                  <li class="partner-logo-lg animated fadeInUp"><img className='partner-img' src={partner5} alt="logo" /></li>
                </ul>
              </ScrollAnimation>
            </div>

          </div>
        </section>

        <section class="section bg-theme tc-light sec-blog" id="blog">
          <div class="container">
            <div class="section-head text-center wide-auto-sm">
              <ScrollAnimation animateIn="fadeInUp" delay="0.5">
                <h2 class="title title-s4 animated fadeInUp press-text" >Blog</h2>
              </ScrollAnimation>
            </div>
            <div class="nk-block nk-block-blog">
              <div class="row justify-content-center">
                <div class="col-lg-4 col-sm-9">
                  <ScrollAnimation animateIn="fadeInUp" delay="0.5">
                    <div class="blog blog-s2 animated fadeInUp">
                      <div class="blog-photo">
                        <img src={blog1} alt="blog-thumb" className='blog-img' />
                      </div>
                      <div class="blog-text text-left">
                        <ul class="blog-meta">
                          <li><a href="#" className='text-left animated fadeInUp '>12 Mar, 2022</a></li>
                          <li><a href="#" className='text-left animated fadeInUp'>Blockchain</a></li>
                        </ul>
                        <h4 class="title title-sm text-left blog-title animated fadeInUp"><a href="#">The Intersection with<br /> Blockchain.</a></h4>
                        <p className='text-left'>Blockchain Meets Energy Surplus of electrical energy is sometimes ut perspiciatis unde omnis iste natus...</p>
                      </div>
                    </div>
                  </ScrollAnimation>
                </div>
                <div class="col-lg-4 col-sm-9">
                  <ScrollAnimation animateIn="fadeInUp" delay="0.5">
                    <div class="blog blog-s2 animated fadeInUp">
                      <div class="blog-photo">
                        <img src={blog2} alt="blog-thumb" className='blog-img' />
                      </div>
                      <div class="blog-text text-left">
                        <ul class="blog-meta">
                          <li><a href="#" className='text-left animated fadeInUp'>12 Mar, 2022</a></li>
                          <li><a href="#" className='text-left animated fadeInUp'>Blockchain</a></li>
                        </ul>
                        <h4 class="title title-sm text-left blog-title animated fadeInUp"><a href="#">The Intersection with<br /> Blockchain.</a></h4>
                        <p className='text-left'>Blockchain Meets Energy Surplus of electrical energy is sometimes ut perspiciatis unde omnis iste natus...</p>
                      </div>
                    </div>
                  </ScrollAnimation>
                </div>
                <div class="col-lg-4 col-sm-9">
                  <ScrollAnimation animateIn="fadeInUp" delay="0.5">
                    <div class="blog blog-s2 animated fadeInUp">
                      <div class="blog-photo">
                        <img src={blog3} alt="blog-thumb" className='blog-img' />
                      </div>
                      <div class="blog-text text-left">
                        <ul class="blog-meta">
                          <li><a href="#" className='text-left animated fadeInUp'>12 Mar, 2022</a></li>
                          <li><a href="#" className='text-left animated fadeInUp'>Blockchain</a></li>
                        </ul>
                        <h4 class="title title-sm blog-title animated fadeInUp"><a href="#" className='text-left'>The Intersection with<br /> Blockchain.</a></h4>
                        <p className='text-left animated fadeInUp'>Blockchain Meets Energy Surplus of electrical energy is sometimes ut perspiciatis unde omnis iste natus...</p>
                      </div>
                    </div>
                  </ScrollAnimation>
                </div>
              </div>
            </div>
            <div class="text-center pdt-r animated fadeInUp">
              <ScrollAnimation animateIn="fadeInUp" delay="0.5">
                <a href="#" class="link link-primary link-uc link-animate animated fadeInUp"><span>Read all News</span> <em class="link-icon ti ti-angle-right"></em></a>
              </ScrollAnimation>
            </div>
          </div>
        </section>

        <section class="faq-sec section-bg" id='faq'>
          <div class="container">
            <div class="section-head text-center wide-auto-sm">
              <ScrollAnimation animateIn="fadeInUp" delay="0.5">
              <a href='https://www.explicitcoin.com' target='blnck' class="title title-s4 animated fadeInUp mb-5" title="FAQS">Frequently Asked Question</a>
                <p class="animated fadeInUp faq-main-para">Below we’ve provided a bit of ICO, ICO Token, cryptocurrencies, and few others. If you have any other questions, please get in touch using the contact form below</p>
              </ScrollAnimation>
            </div>

            <div class="nk-block overflow-hidden">
              <div class="row justify-content-center">
                <div class="col-xl-8 col-lg-10">
                  <ScrollAnimation animateIn="fadeInUp" delay="0.5">
                    <ul class="nav tab-nav tab-nav-line mgb-r animated fadeInUp">
                      <li><a class="active" data-bs-toggle="tab" href="#general-questions-19">General</a></li>
                      <li><a data-bs-toggle="tab" href="#ico-questions-19">Pre-ICO &amp; ICO</a></li>
                      <li><a data-bs-toggle="tab" href="#tokens-sales-19">Token</a></li>
                      <li><a data-bs-toggle="tab" href="#client-19">Client</a></li>
                      <li><a data-bs-toggle="tab" href="#legal-19">Legal</a></li>
                    </ul>
                    <div class="tab-content animated fadeInUp">
                      <div class="tab-pane fade show active" id="general-questions-19">
                        <div class="accordion accordion-s1 accordion-faq " id="faq-67">
                          <div class="accordion-item accordion-item-s4 bg-light current">
                            <h5 class="accordion-title" data-bs-toggle="collapse" data-bs-target="#faq-67-1"> What is ICO Crypto? <span class="accordion-icon accordion-icon-s2"></span>
                            </h5>
                            <div id="faq-67-1" class="collapse show" data-bs-parent="#faq-67">
                              <div class="accordion-content">
                                <p className='faq-para'>Once ICO period is launched, You can purchased Token with Etherum, Bitcoin or Litecoin. You can also tempor incididunt ut labore et dolore magna aliqua sed do eiusmod eaque ipsa.</p>
                              </div>
                            </div>
                          </div>
                          <div class="accordion-item accordion-item-s4 bg-light">
                            <h5 class="accordion-title collapsed" data-bs-toggle="collapse" data-bs-target="#faq-67-2"> What cryptocurrencies can I use to purchase? <span class="accordion-icon accordion-icon-s2"></span>
                            </h5>
                            <div id="faq-67-2" class="collapse" data-bs-parent="#faq-67">
                              <div class="accordion-content">
                                <p className='faq-para'>Once ICO period is launched, You can purchased Token with Etherum, Bitcoin or Litecoin. You can also tempor incididunt ut labore et dolore magna aliqua sed do eiusmod eaque ipsa.</p>
                              </div>
                            </div>
                          </div>
                          <div class="accordion-item accordion-item-s4 bg-light">
                            <h5 class="accordion-title collapsed" data-bs-toggle="collapse" data-bs-target="#faq-67-3"> How can I participate in the ICO Token sale? <span class="accordion-icon accordion-icon-s2"></span>
                            </h5>
                            <div id="faq-67-3" class="collapse" data-bs-parent="#faq-67">
                              <div class="accordion-content">
                                <p className='faq-para'>Once ICO period is launched, You can purchased Token with Etherum, Bitcoin or Litecoin. You can also tempor incididunt ut labore et dolore magna aliqua sed do eiusmod eaque ipsa.</p>
                              </div>
                            </div>
                          </div>
                          <div class="accordion-item accordion-item-s4 bg-light">
                            <h5 class="accordion-title collapsed" data-bs-toggle="collapse" data-bs-target="#faq-67-4"> How do I benefit from the ICO Token? <span class="accordion-icon accordion-icon-s2"></span>
                            </h5>
                            <div id="faq-67-4" class="collapse" data-bs-parent="#faq-67">
                              <div class="accordion-content">
                                <p className='faq-para'>Once ICO period is launched, You can purchased Token with Etherum, Bitcoin or Litecoin. You can also tempor incididunt ut labore et dolore magna aliqua sed do eiusmod eaque ipsa.</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="tab-pane fade" id="ico-questions-19">
                        <div class="accordion accordion-s1 accordion-faq" id="faq-68">
                          <div class="accordion-item accordion-item-s4 bg-light current">
                            <h5 class="accordion-title" data-bs-toggle="collapse" data-bs-target="#faq-68-1"> Which of us ever undertakes laborious? <span class="accordion-icon accordion-icon-s2"></span>
                            </h5>
                            <div id="faq-68-1" class="collapse show" data-bs-parent="#faq-68">
                              <div class="accordion-content">
                                <p className='faq-para'>Once ICO period is launched, You can purchased Token with Etherum, Bitcoin or Litecoin. You can also tempor incididunt ut labore et dolore magna aliqua sed do eiusmod eaque ipsa.</p>
                              </div>
                            </div>
                          </div>
                          <div class="accordion-item accordion-item-s4 bg-light">
                            <h5 class="accordion-title collapsed" data-bs-toggle="collapse" data-bs-target="#faq-68-2"> Who do not know how to pursue rationally? <span class="accordion-icon accordion-icon-s2"></span>
                            </h5>
                            <div id="faq-68-2" class="collapse" data-bs-parent="#faq-68">
                              <div class="accordion-content">
                                <p className='faq-para'>Once ICO period is launched, You can purchased Token with Etherum, Bitcoin or Litecoin. You can also tempor incididunt ut labore et dolore magna aliqua sed do eiusmod eaque ipsa.</p>
                              </div>
                            </div>
                          </div>
                          <div class="accordion-item accordion-item-s4 bg-light">
                            <h5 class="accordion-title collapsed" data-bs-toggle="collapse" data-bs-target="#faq-68-3"> Their separate existence is a myth? <span class="accordion-icon accordion-icon-s2"></span>
                            </h5>
                            <div id="faq-68-3" class="collapse" data-bs-parent="#faq-68">
                              <div class="accordion-content">
                                <p className='faq-para'>Once ICO period is launched, You can purchased Token with Etherum, Bitcoin or Litecoin. You can also tempor incididunt ut labore et dolore magna aliqua sed do eiusmod eaque ipsa.</p>
                              </div>
                            </div>
                          </div>
                          <div class="accordion-item accordion-item-s4 bg-light">
                            <h5 class="accordion-title collapsed" data-bs-toggle="collapse" data-bs-target="#faq-68-4"> Pityful a rethoric question ran over her cheek? <span class="accordion-icon accordion-icon-s2"></span>
                            </h5>
                            <div id="faq-68-4" class="collapse" data-bs-parent="#faq-68">
                              <div class="accordion-content">
                                <p className='faq-para'>Once ICO period is launched, You can purchased Token with Etherum, Bitcoin or Litecoin. You can also tempor incididunt ut labore et dolore magna aliqua sed do eiusmod eaque ipsa.</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="tab-pane fade" id="tokens-sales-19">
                        <div class="accordion accordion-s1 accordion-faq" id="faq-69">
                          <div class="accordion-item accordion-item-s4 bg-light current">
                            <h5 class="accordion-title" data-bs-toggle="collapse" data-bs-target="#faq-69-1"> When she reached the first hills? <span class="accordion-icon accordion-icon-s2"></span>
                            </h5>
                            <div id="faq-69-1" class="collapse show" data-bs-parent="#faq-69">
                              <div class="accordion-content">
                                <p className='faq-para'>Once ICO period is launched, You can purchased Token with Etherum, Bitcoin or Litecoin. You can also tempor incididunt ut labore et dolore magna aliqua sed do eiusmod eaque ipsa.</p>
                              </div>
                            </div>
                          </div>
                          <div class="accordion-item accordion-item-s4 bg-light">
                            <h5 class="accordion-title collapsed" data-bs-toggle="collapse" data-bs-target="#faq-69-2"> Big Oxmox advised her not to do? <span class="accordion-icon accordion-icon-s2"></span>
                            </h5>
                            <div id="faq-69-2" class="collapse" data-bs-parent="#faq-69">
                              <div class="accordion-content">
                                <p className='faq-para'>Once ICO period is launched, You can purchased Token with Etherum, Bitcoin or Litecoin. You can also tempor incididunt ut labore et dolore magna aliqua sed do eiusmod eaque ipsa.</p>
                              </div>
                            </div>
                          </div>
                          <div class="accordion-item accordion-item-s4 bg-light">
                            <h5 class="accordion-title collapsed" data-bs-toggle="collapse" data-bs-target="#faq-69-3"> Which roasted parts of sentences fly into your mouth? <span class="accordion-icon accordion-icon-s2"></span>
                            </h5>
                            <div id="faq-69-3" class="collapse" data-bs-parent="#faq-69">
                              <div class="accordion-content">
                                <p className='faq-para'>Once ICO period is launched, You can purchased Token with Etherum, Bitcoin or Litecoin. You can also tempor incididunt ut labore et dolore magna aliqua sed do eiusmod eaque ipsa.</p>
                              </div>
                            </div>
                          </div>
                          <div class="accordion-item accordion-item-s4 bg-light">
                            <h5 class="accordion-title collapsed" data-bs-toggle="collapse" data-bs-target="#faq-69-4"> Vokalia and Consonantia, there live? <span class="accordion-icon accordion-icon-s2"></span>
                            </h5>
                            <div id="faq-69-4" class="collapse" data-bs-parent="#faq-69">
                              <div class="accordion-content">
                                <p className='faq-para'>Once ICO period is launched, You can purchased Token with Etherum, Bitcoin or Litecoin. You can also tempor incididunt ut labore et dolore magna aliqua sed do eiusmod eaque ipsa.</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="tab-pane fade" id="client-19">
                        <div class="accordion accordion-s1 accordion-faq" id="faq-70">
                          <div class="accordion-item accordion-item-s4 bg-light current">
                            <h5 class="accordion-title" data-bs-toggle="collapse" data-bs-target="#faq-70-1"> When she reached the first hills? <span class="accordion-icon accordion-icon-s2"></span>
                            </h5>
                            <div id="faq-70-1" class="collapse show" data-bs-parent="#faq-70">
                              <div class="accordion-content">
                                <p className='faq-para'>Once ICO period is launched, You can purchased Token with Etherum, Bitcoin or Litecoin. You can also tempor incididunt ut labore et dolore magna aliqua sed do eiusmod eaque ipsa.</p>
                              </div>
                            </div>
                          </div>
                          <div class="accordion-item accordion-item-s4 bg-light">
                            <h5 class="accordion-title collapsed" data-bs-toggle="collapse" data-bs-target="#faq-70-2"> Big Oxmox advised her not to do? <span class="accordion-icon accordion-icon-s2"></span>
                            </h5>
                            <div id="faq-70-2" class="collapse" data-bs-parent="#faq-70">
                              <div class="accordion-content">
                                <p className='faq-para'>Once ICO period is launched, You can purchased Token with Etherum, Bitcoin or Litecoin. You can also tempor incididunt ut labore et dolore magna aliqua sed do eiusmod eaque ipsa.</p>
                              </div>
                            </div>
                          </div>
                          <div class="accordion-item accordion-item-s4 bg-light">
                            <h5 class="accordion-title collapsed" data-bs-toggle="collapse" data-bs-target="#faq-70-3"> Which roasted parts of sentences fly into your mouth? <span class="accordion-icon accordion-icon-s2"></span>
                            </h5>
                            <div id="faq-70-3" class="collapse" data-bs-parent="#faq-70">
                              <div class="accordion-content">
                                <p className='faq-para'>Once ICO period is launched, You can purchased Token with Etherum, Bitcoin or Litecoin. You can also tempor incididunt ut labore et dolore magna aliqua sed do eiusmod eaque ipsa.</p>
                              </div>
                            </div>
                          </div>
                          <div class="accordion-item accordion-item-s4 bg-light">
                            <h5 class="accordion-title collapsed" data-bs-toggle="collapse" data-bs-target="#faq-70-4"> Vokalia and Consonantia, there live? <span class="accordion-icon accordion-icon-s2"></span>
                            </h5>
                            <div id="faq-70-4" class="collapse" data-bs-parent="#faq-70">
                              <div class="accordion-content">
                                <p className='faq-para'>Once ICO period is launched, You can purchased Token with Etherum, Bitcoin or Litecoin. You can also tempor incididunt ut labore et dolore magna aliqua sed do eiusmod eaque ipsa.</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="tab-pane fade" id="legal-19">
                        <div class="accordion accordion-s1 accordion-faq" id="faq-71">
                          <div class="accordion-item accordion-item-s4 bg-light current">
                            <h5 class="accordion-title" data-bs-toggle="collapse" data-bs-target="#faq-71-1"> When she reached the first hills? <span class="accordion-icon accordion-icon-s2"></span>
                            </h5>
                            <div id="faq-71-1" class="collapse show" data-bs-parent="#faq-71">
                              <div class="accordion-content">
                                <p className='faq-para'>Once ICO period is launched, You can purchased Token with Etherum, Bitcoin or Litecoin. You can also tempor incididunt ut labore et dolore magna aliqua sed do eiusmod eaque ipsa.</p>
                              </div>
                            </div>
                          </div>
                          <div class="accordion-item accordion-item-s4 bg-light">
                            <h5 class="accordion-title collapsed" data-bs-toggle="collapse" data-bs-target="#faq-71-2"> Big Oxmox advised her not to do? <span class="accordion-icon accordion-icon-s2"></span>
                            </h5>
                            <div id="faq-71-2" class="collapse" data-bs-parent="#faq-71">
                              <div class="accordion-content">
                                <p className='faq-para'>Once ICO period is launched, You can purchased Token with Etherum, Bitcoin or Litecoin. You can also tempor incididunt ut labore et dolore magna aliqua sed do eiusmod eaque ipsa.</p>
                              </div>
                            </div>
                          </div>
                          <div class="accordion-item accordion-item-s4 bg-light">
                            <h5 class="accordion-title collapsed" data-bs-toggle="collapse" data-bs-target="#faq-71-3"> Which roasted parts of sentences fly into your mouth? <span class="accordion-icon accordion-icon-s2"></span>
                            </h5>
                            <div id="faq-71-3" class="collapse" data-bs-parent="#faq-71">
                              <div class="accordion-content">
                                <p className='faq-para'>Once ICO period is launched, You can purchased Token with Etherum, Bitcoin or Litecoin. You can also tempor incididunt ut labore et dolore magna aliqua sed do eiusmod eaque ipsa.</p>
                              </div>
                            </div>
                          </div>
                          <div class="accordion-item accordion-item-s4 bg-light">
                            <h5 class="accordion-title collapsed" data-bs-toggle="collapse" data-bs-target="#faq-71-4"> Vokalia and Consonantia, there live? <span class="accordion-icon accordion-icon-s2"></span>
                            </h5>
                            <div id="faq-71-4" class="collapse" data-bs-parent="#faq-71">
                              <div class="accordion-content">
                                <p className='faq-para'>Once ICO period is launched, You can purchased Token with Etherum, Bitcoin or Litecoin. You can also tempor incididunt ut labore et dolore magna aliqua sed do eiusmod eaque ipsa.</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ScrollAnimation>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="contact-sec section-bg" id='contact'>
          <div class="container">
            <div class="section-head text-center wide-auto-sm">
              <ScrollAnimation animateIn="fadeInUp" delay="0.5">
                <h2 class="title title-s4 animated fadeInUp" title="Contact">Contact ICO Crypto</h2>
                <p class="animated fadeInUp contact-para">Any question? Reach out to us and we’ll get back to you shortly.</p>
              </ScrollAnimation>
            </div>
            <div class="nk-block block-contact">
              <div class="row justify-content-center text-center">
                <div class="col-xl-8 col-lg-10">
                  <ScrollAnimation animateIn="fadeInUp" delay="0.5">
                    <ul class="contact-list contact-list-s1 flex-wrap flex-md-nowrap pdb-l">
                      <li class="animated fadeInUp">
                        <em class="contact-icon fas fa-phone"></em>
                        <div class="contact-text">
                          <span className='contact-data'>+44 0123 4567</span>
                        </div>
                      </li>
                      <li class="animated fadeInUp">
                        <em class="contact-icon fas fa-envelope"></em>
                        <div class="contact-text">
                          <span className='contact-data'>info@company.com</span>
                        </div>
                      </li>
                      <li class="animated fadeInUp">
                        <em class="contact-icon fas fa-paper-plane"></em>
                        <div class="contact-text">
                          <span className='contact-data'>Join us on Telegram</span>
                        </div>
                      </li>
                    </ul>
                  </ScrollAnimation>
                </div>
                <div class="col-xl-8 col-lg-10">
                  <ScrollAnimation animateIn="fadeInUp" delay="0.5">
                    <div class="contact-wrap p-0">
                      <form id="contact-form-01" class="contact-form nk-form-submit" action="form/contact.php" method="post" novalidate="novalidate">
                        <div class="field-item field-item-center animated fadeInUp " data-animate="fadeInUp">
                          <input name="contact-name" type="text" class="input-line required contact-input" placeholder="Your Name" />
                        </div>
                        <div class="field-item field-item-center animated fadeInUp " data-animate="fadeInUp">
                          <input name="contact-name" type="email" class="input-line required contact-input" placeholder="Your Email" />
                        </div>
                        <div class="field-item field-item-center animated fadeInUp" data-animate="fadeInUp">
                          <textarea name="contact-message" class="input-line input-line-center contact-text-area contact-input input-textarea required" placeholder='Your Messages '></textarea>
                        </div>
                        <div class="field-wrap animated fadeInUp" >
                          <ScrollAnimation animateIn="fadeInUp" delay="0.5">
                            <button type="submit" class="btn btn-round btn-lg btn-grad btn-primary">Submit</button>
                          </ScrollAnimation>
                          <div class="form-results"></div>
                        </div>
                      </form>
                    </div>
                  </ScrollAnimation>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </div>
  )
}

export default Home
