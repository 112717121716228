import React from 'react'
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';

const VerifyMail = () => {
  const auth = localStorage.getItem("auth");
  const params = useParams();
  const navigate = useNavigate();
  useEffect(() => { checkVrifyEmail() }, []);

  const checkVrifyEmail = () => {
    
    if (params?.id) {
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/x-www-form-urlencoded",auth },
      };
      // fetch(`http://localhost:3001/email/verify/${params.id}`,requestOptions)
      fetch(`https://pms.virtualheight.com/explicit/explicit/email/verify/${params.id}`,requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if(data?.user){
            navigate('/account')
            toast.success('Mail verified')
          }else{
            // navigate('/account')
            toast.error(data?.message||'somthng want to wrong')
          }
         
          //   setUser(data.data);
        }).catch(err=>{console.log(err,'error')});
    }
  }
  return (
    <div className="page">
      <div className="loader">
        <div className="loader__status"></div>
      </div>
    </div>
  )
}
export default VerifyMail