import React, { useState } from 'react'
import './login.style.css'
import { useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'

const Login = () => {
  const navigate = useNavigate()
  const [email, setEmail] = useState()
  const [password, setPassword] = useState()

  const [emailError, setEmailError] = useState()
  const [passwordError, setpasswordError] = useState('')

  const handleSubmit = (e) => {
    e.preventDefault()

    if (!email) {
      setEmailError('Please Enter email')
    } else {
      setEmailError('')
    }
    if (!password) {
      setpasswordError('Please Enter Password')
    } else {
      setpasswordError('')
    }

    console.log(window.solana, 'token')

    if (email !== '' && password !== '') {
      console.log('In')
      let userData = new URLSearchParams()
      userData.append('email', email)
      userData.append('password', password)

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: userData,
      }

      // fetch('http://localhost:3001/login', requestOptions)
      fetch('https://pms.virtualheight.com/explicit/explicit/login', requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data.status === '1') {
            console.log(data.data[0], 'hh12')
            
            toast.success(data.message)
            localStorage.setItem('loginUser', data.data[0].email)
            localStorage.setItem('auth', data.data[0].auth)
            localStorage.setItem('isUserLogin', window.btoa("true"))
            setTimeout(()=>{
              navigate('/dashboard')
              window.location.href = '/dashboard';
            },1500)
          } else {
            toast.error(data.message)
          }
          console.log(data, 'res')
        })
    }

    console.log(email, password)
  }

  return (
    <div className="login-container">
      <ToastContainer />
      <form className="loginform">
        <h4 className="form-title">Sign In</h4>
        <div className="input-container">
          <label className="label">Email: </label>
          <input
            type="text"
            name="email"
            className="input"
            placeholder="Username"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <p style={{ color: 'red' }}>{emailError}</p>
        </div>
        <div className="input-container">
          <label className="label">Password: </label>
          <input
            type="password"
            name="password"
            className="input"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <p style={{ color: 'red' }}>{passwordError}</p>
          <a href="#" className="link forgotten-password">
            Forgot password?
          </a>
        </div>

        <button type="submit" id="login-btn" onClick={handleSubmit}>
          Login
        </button>
        <a href="#" className="signuplink">
        <a href="/signup"> Don't have an account? Sign Up</a>
        </a>
      </form>
    </div>
  )
}

export default Login
