import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import userthumblg from "../admin-assets/images/user-thumb-lg.png";
import closemenu from '../images/home/close.png';

let init_account = false;
const Sidebar = ({isActive, setDrawer}) => {
  const [userName, setUserName] = useState("");

  const [user, setUser] = useState("");
  const [walletId,setWalletId]=useState("");

  // const [drawer,setDrawer]=useState('')
  // useEffect(()=>{setDrawer(props)},[props])
  console.log(isActive,"props");
  // console.log(drawer,"drawer");
  console.log(walletId,"walletId");

  const getUserData = () => {
    const email = localStorage.getItem("loginUser");
    const auth = localStorage.getItem("auth");

    let userData = new URLSearchParams();

    userData.append("email", email);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded",auth },
      body: userData,
    };

    fetch("https://pms.virtualheight.com/explicit/explicit/getuser", requestOptions)
    // fetch("http://localhost:3001/getuser", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        console.log(data.data, "hh12");
        setUser(data.data);
      });
  };

  const handleCloseDrawer = ()=>{
    setDrawer('') 
  }
 

  // const drawerHandler =()=>{
  //   console.log("38");
  //   if(props==="active"){
  //     setDrawer("")
  //   }else{
  //     setDrawer("active")
  //   }
  // }

  useEffect(() => {
    getUserData();
    setWalletId(window?.solana?.publicKey?.toBase58());
  }, [isActive]);

  useEffect(() => {
    if (window.solana?.publicKey) {
      localStorage.setItem("tokenid", window.solana?.publicKey?.toBase58());
    }
  }, []);

  let [accountLink, setAccountLink] = useState(
    // window?.solana?.isConnected ? "/account" : "#"
    "/account"
  );

  // if (init_account == false) {
  //   let a = setInterval(() => {
  //     let status = window?.solana?.isConnected;
  //     if (status === true) {
  //       setAccountLink("/account");
  //     } else {
  //       setAccountLink("#");
  //     }
  //   }, 1000);
  //   init_account = true;
  // }

  
  const downloadFile = () => {
    // using Java Script method to get PDF file
    fetch("./pdf/whitepaper.pdf").then((response) => {
      response.blob().then((blob) => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = "SamplePDF.pdf";
        alink.click();
      });
    });
  };

 

  return (
    <div className="page-top-gap">
      
      {/* <div class={`user-sidebar user-sidebar-mobile open-mobile-sidebaar ${props==="active"?props:""}`}> */}
      {console.log(isActive,'ccccccccccc')}
      <a onClick={handleCloseDrawer}>
      <div class={`user-sidebar user-sidebar-mobile open-mobile-sidebaar ${isActive}`}>
        <div class="user-sidebar-overlay"></div>
        <div class="user-box d-none d-lg-block user-image-box">
          <div class="user-image">
            <img src={userthumblg} alt="thumb" />
          </div>
          <h6 class="user-name">{user?.userName}</h6>
          <div class="user-uid">
            {/* Wallet ID: <span>{window.solana?.publicKey?.toBase58()}</span> */}
          </div>
          {/* <ul class="btn-grp guttar-10px user-info-sidebar">
                        <li><a href="#" class="btn btn-xs btn-warning">Confirm Email</a></li>
                        <li><a href="#" class="btn btn-xs btn-warning">KYC Pending</a></li>
                    </ul> */}
        </div>
        
        <img src={closemenu} onClick={handleCloseDrawer} className="close-menu-icon"></img>
        {/* <button onClick={drawerHandler}>click</button> */}
        <ul class="user-icon-nav">
          <li class="active">
            <Link to={"/dashboard"}>
              <em class="ti ti-dashboard"></em>Dashboard
            </Link>
          </li>
          {/* <li><Link to={"/kycapplication"}><em class="ti ti-files"></em>KYC Application</Link></li> */}
          <li>
            <Link to={"/contributions"}>
              <em class="ti ti-pie-chart"></em>Contributions
            </Link>
          </li>
          <li>
            <Link to={"/transactions"}>
              <em class="ti ti-control-shuffle"></em>Transactions
            </Link>
          </li>
          <li>
            <Link
              to={accountLink}
              // onClick={() => {
              //   if (accountLink == "#") {
              //     alert("First Conenct your wallet ");
              //   }
              // }}
            >
              <em class="ti ti-user"></em>Account
            </Link>
          </li>
          {/* <li><Link to={"/security"}><em class="ti ti-lock"></em>Security</Link></li> */}
        </ul>
        <div class="user-sidebar-sap"></div>
        <ul class="user-nav">
          <li>
        <Link to={"/#faq"}>Faqs</Link>
          </li>
          <li role="button" onClick={downloadFile}>
            <a href="#">Whitepaper</a>
          </li>
          <li>
            <a href="https://mail.google.com/mail/?view=cm&fs=1&to=info@explicitcoin.com">
              Contact Support<span>info@explicitcoin.com</span>
            </a>
          </li>
        </ul>
        <div class="d-lg-none">
          <div class="user-sidebar-sap"></div>
          <div class="gaps-1x"></div>
          <ul class="topbar-action-list">
            <li class="topbar-action-item topbar-action-link">
              <a href="#">
                <em class="ti ti-home"></em> Go to main site
              </a>
            </li>
            <li class="dropup topbar-action-item topbar-action-language">
              <a href="#" data-bs-toggle="dropdown" aria-haspopup="true">
                {" "}
                EN <em class="ti ti-angle-up"></em>{" "}
              </a>
              <ul class="dropdown-menu">
                <li>
                  <a href="#">FR</a>
                </li>
                <li>
                  <a href="#">JY</a>
                </li>
                <li>
                  <a href="#">CH</a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        
      </div>
      </a>
    </div>
  );
};

export default Sidebar;
