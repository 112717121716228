import React, { useState } from 'react'
import userthumbsm from '../admin-assets/images/user-thumb-sm.png'
import logo from '../images/home/03a.png'
import {WalletButton} from '../utils/WalletButton';
import {UserWalletButton} from '../utils/UserWalletButton';
import Sidebar from './Sidebar';
import { useEffect } from 'react';
import $ from 'jquery'
import mobilemenu from '../images/home/mobilemenu.png';
import closemenu from '../images/home/close.png';

const TopBar = () => { 
  const [user, setUser] = useState("");
  const [active,setActive]=useState("")

  console.log(active,"active");

  const getUserData = () => {
    const email = localStorage.getItem("loginUser");
    const auth = localStorage.getItem("auth");
    
    let userData = new URLSearchParams();

    userData.append("email", email);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded",auth },
      body: userData,
    };

    fetch("https://pms.virtualheight.com/explicit/explicit/getuser", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        console.log(data.data, "hh12");
        setUser(data.data);
      });
  };

  const handelOpenDrawer =()=>{
      setActive("active")
    }

    const logOutHandler =()=>{
      localStorage.removeItem("loginUser");
      localStorage.removeItem("isUserLogin");
    }
  

  useEffect(() => {
    getUserData();
  }, [active]);


 

  return (
    <div>
      <div class="topbar">
        <div class="topbar-md d-lg-none">
          <div class="container">
            <div class="d-flex align-items-center justify-content-between">
              <a className='sidebar-main'> 
                {/* <div class="toggle-icon">
                  <span class="toggle-line"></span>
                  <span class="toggle-line"></span>
                  <span class="toggle-line"></span>
                  <span class="toggle-line"></span>
                </div> */}
                <div className='mobilemenu-icon-box'>
                    <img src={mobilemenu} onClick={handelOpenDrawer} className="mobilemenu-icon"></img>
                </div>
                {/* <button >click</button> */}
                <Sidebar setDrawer={(v)=>{setActive(v)}} isActive={active}/>
              </a>
              <div class="site-logo">
                <a href='#' class="site-brand">
                  <img src={logo} alt="logo" className='logo-main' />
                </a>
              </div>
              <div className='mobile-user-main'>
              <div className='mobile-wallet-main'>
              <WalletButton/>
              </div>
              <div class="dropdown topbar-action-item topbar-action-user">
                <a href="#" data-bs-toggle="dropdown">
                  {' '}
                  <img class="icon" src={userthumbsm} alt="thumb" />{' '}
                </a>
                <div class="dropdown-menu dropdown-menu-end">
                  <div class="user-dropdown">
                    <div class="user-dropdown-head">
                      <h6 class="user-dropdown-name">
                      Stefan Harary  <span>(IXIA1A105)</span>
                      </h6>
                      <span class="user-dropdown-email">
                        useremail@example.com
                      </span>
                    </div>
                    <div class="user-dropdown-balance">
                      {/* <h6>EXPLICIT TOKEN BALANCE</h6> */}
                      {/* <h3>120,000,000 IC0X</h3> */}
                      {/* <ul> */}
                        {/* <li>1.240 BTC</li> */}
                        {/* <li>19.043 ETH</li> */}
                        {/* <li>6,500.13 USD</li> */}
                      {/* </ul> */}
                    </div>
                    <ul class="btn-grp guttar-10px">
                      <li>
                        <a href="#" class="btn btn-xs btn-warning">
                          Confirm Email
                        </a>
                      </li>
                      <li>
                        <a href="#" class="btn btn-xs btn-warning">
                          KYC Pending
                        </a>
                      </li>
                    </ul>
                    <div class="gaps-1x"></div>
                    <ul class="user-dropdown-links">
                      <li>
                        <a href="#">
                          <i class="ti ti-id-badge"></i>My Profile
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i class="ti ti-lock"></i>Security
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i class="ti ti-eye"></i>Activity
                        </a>
                      </li>
                    </ul>
                    <ul class="user-dropdown-links">
                      <li>
                        <a href="#">
                          <i class="ti ti-power-off"></i>Logout
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="d-lg-flex align-items-center justify-content-between">
            <div class="topbar-lg d-none d-lg-block">
              <div class="site-logo">
                <a href="https://www.explicitcoin.com" class="site-brand">
                  {/* <img src={logo} alt="logo" srcset="images/logo2x.png 2x" /> */}
                  <img src={logo} alt="logo" />
                </a>
              </div>
            </div>
            <div class="topbar-action d-none d-lg-block">
              <ul class="topbar-action-list">
                <li class="topbar-action-item topbar-action-link">
                    <em class="ti ti-home"></em> 
                  <a href="https://www.explicitcoin.com">
                    Go to main site
                  </a>
                </li>
                {/* //! Temp */}
                {/* <li class="dropdown topbar-action-item topbar-action-language">
                  <a href="#" data-bs-toggle="dropdown">
                    {' '}
                    EN <em class="ti ti-angle-down"></em>{' '}
                  </a>
                  <ul class="dropdown-menu">
                    <li>
                      <a href="#">FR</a>
                    </li>
                    <li>
                      <a href="#">JY</a>
                    </li>
                    <li>
                      <a href="#">CH</a>
                    </li>
                  </ul>
                </li> */}
                <li class="dropdown topbar-action-item topbar-action-user">
                  <a href="#" data-bs-toggle="dropdown">
                    {' '}
                    <img class="icon" src={userthumbsm} alt="thumb" />{' '}
                  </a>
                  <div class="dropdown-menu dropdown-menu-end">
                    <div class="user-dropdown">
                      <div class="user-dropdown-head">
                        <h6 class="user-dropdown-name">
                        {user?.userName} 
                        </h6>
                        <span class="user-dropdown-email">
                          {user?.email}
                        </span>
                      </div>
                      {/* //! Need to Improve */}
                      {/* <div class="user-dropdown-balance">
                        <h6>EXPLICIT TOKEN BALANCE</h6>
                        <h3>120,000,000 IC0X</h3>
                        <ul>
                          <li>1.240 BTC</li>
                          <li>19.043 ETH</li>
                          <li>6,500.13 USD</li>
                        </ul>
                      </div> */}
                      <ul class="user-dropdown-links">
                       
                        <li>
                          <a href="/" onClick={logOutHandler}>
                            <i class="ti ti-eye"></i>Log out
                          </a>
                        </li>
                      </ul>
                      {/* //! NEED to improve */}
                      {/* <ul class="user-dropdown-links">
                        <li>
                          <a href="#">
                            <i class="ti ti-power-off"></i>Logout
                          </a>
                        </li>
                      </ul> */}
                    </div>
                  </div>
                </li>
                <li>
                {/* <button id = "connectionSignal" style={{backgroundColor:'red'}}><h6 style={{color: "transparent"}}>+</h6></button> */}
                  {/* //! Adding Walletbutton Creates's an Error. */}
                  <WalletButton/>
                  {/* So i created my own button */}
                  {/* <UserWalletButton/>  */}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TopBar
