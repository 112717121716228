import { getTokenAccountHistory } from './getTokenAccountHistory'
import { getTokenTransactionInfo } from './getTokenTransactionInfo'
import * as anchor from '@project-serum/anchor'
import { useAnchorWallet } from '@solana/wallet-adapter-react'

async function createList(key, listArea, connection) {
  let history = await getTokenAccountHistory(connection, key)

  if (history == null) {
    listArea.innerHTML =
      '<tr><td colspan=5> <h3 align="center">You Haven\'t bought token</h3> </td></tr>'
    return null
  }

  let listTileHtml = ''
  listArea.innerHTML = listTileHtml
  let listUpdate = false

  let len = history.length
  let i = 0
  while (i < len) {
    let info = await getTokenTransactionInfo(connection, history[i].signature)
    if (info != null) {
      listTileHtml = createListTile(info)
      listArea.innerHTML += listTileHtml
      listUpdate = true
    }
    i += 1
  }

  if (listUpdate === false) {
    //! Error because of double calling function.
    listArea.innerHTML =
      '<tr><td colspan=5> <h3 align="center">May be you never bought any token !</h3> </td></tr>'
  }
}

function createListTile(data) {
  const sign = data.sign
  const cost = data.cost
  const boughtToken = data.boughtToken
  const boughtTime = data.boughtTime

  const listStr = `
    <tr class="odd">
      <td class="tranx-status tranx-status-approved">
        <span class="d-none">Approved</span>
        <em class="ti ti-check"></em>
      </td>
      <td class="tranx-no">
        <span>
          <a href="https://explorer.solana.com/tx/${sign}?cluster=devnet" target=blank>
          ${sign
            .substring(0, 12)
            .concat('...')
            .concat(sign.substring(sign.length - 12, sign.length - 1))}
        </a>
        </span>${boughtTime}
      </td>
      <td><span></span></td>
      <td class="tranx-token">
        <span>${boughtToken}</span>XPLCT
      </td>
      <td class="tranx-amount"><span>${cost}</span>${
    cost.split(' ')[1]
  } <em class="fas fa-info-circle" data-bs-toggle="tooltip" data-placement="bottom" title="" data-bs-original-title="1 ETH = 100 ICOX" aria-label="1 ETH = 100 ICOX"></em></td>
    </tr>`

  return listStr
}

async function lister() {
  // await new Promise((resolve) => setTimeout(resolve, 1600)) //? Waitineg for 'transactionList' obj creation.

  const network = 'https://api.devnet.solana.com'
  const connection = new anchor.web3.Connection(network, 'confirmed')
  const listArea = document.getElementById('transactionList')

  let oldKey = null
  setInterval(() => {
    if (window.solana.isConnected != true) {
      listArea.innerHTML =
        '<tr><td colspan=5> <h3 align="center">You are DisConnected</h3> </td></tr>'
      return null
    }
    let key = window?.solana?.publicKey
    if (key != oldKey) {
      oldKey = key
      console.log('Found New key : ', key.toBase58())
      createList(key, listArea, connection)
    }
  }, 2000)
}

// export const Foo = () => {
  export const Lister = () => {
  let a = setTimeout(async () => {
    await lister()
  }, 600)
  return <></>
}
export default Lister


//! Just for Quick development.
async function getUserContrib(){
  const network = 'https://api.devnet.solana.com'
  const connection = new anchor.web3.Connection(network, 'confirmed')
  const userContribArea = document.getElementById('userTotalContrib')
  if (userContribArea == null) return null; 

  let oldKey = null
  setInterval(async () => {
    if (window.solana.isConnected != true) {
      userContribArea.innerHTML = '0'
      return null
    }
    let key = window?.solana?.publicKey
    if (key != oldKey) {
      oldKey = key

      //? Getting and setting the contribValue.
      let history = await getTokenAccountHistory(connection, key)
      if (history == null) {
        userContribArea.innerHTML =
          '0'
        return null
      }
    
      let userContribAreaHtml = '0'
      userContribArea.innerHTML = userContribAreaHtml
      // let listUpdate = false
    
      let len = history.length
      let i = 0
      while (i < len) {
        let info = await getTokenTransactionInfo(connection, history[i].signature)
        if (info != null) {
          userContribAreaHtml = (parseFloat(userContribAreaHtml) + parseFloat(info.boughtToken)).toFixed(2).toString()
          userContribArea.innerHTML = userContribAreaHtml
        }
        i += 1
      }
    }
  }, 2000)
}

export const GetUserContrib = () => {
  setTimeout(async () => {
    await getUserContrib()
  }, 600)
  return <></>
}
