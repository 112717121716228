import xplct_idl from './xplct.json'
import { Buffer } from 'buffer'
import * as anchor from '@project-serum/anchor'

import { utf8 } from '@project-serum/anchor/dist/cjs/utils/bytes'
import React, { useEffect } from 'react'
import { BorshCoder, web3 } from '@project-serum/anchor'
require('@solana/wallet-adapter-react-ui/styles.css')
const network = 'https://api.devnet.solana.com'
const connection = new anchor.web3.Connection(network, 'confirmed')

export const GetDetails = (props) => {
  return (
    <Content
      setLeftToken={props.setLeftToken}
      setTotalToken={props.setTotalToken}
      setRaisedToken={props.setRaisedToken}
    />
  )
}
export default GetDetails

const Content = (props) => {
  window.Buffer = Buffer
  const a = JSON.stringify(xplct_idl)
  const b = JSON.parse(a)
  const program_id = new web3.PublicKey(xplct_idl.metadata.address.toString())
  // const program = new Program(b, program_id, provider)

  let programAccount = null

  // it can be chaged.
  let token_id = new anchor.web3.PublicKey(
    'd55cgyF3QV32UWHUp3j2yvyDUz5ywg713C1Q6Adxmhj',
  )

  const seed1 = token_id.toBytes()
  // const seed2 = utf8.encode('ok9') //? need to change as main deployed  program seed.
  const seed2 = utf8.encode('ok1') //? need to change as main deployed  program seed.

  async function getAndSetAta() {
    let [
      programAccountPda,
      bump,
    ] = await anchor.web3.PublicKey.findProgramAddress(
      [seed1, seed2],
      program_id,
    )

    programAccount = programAccountPda
  }

  const getDetails = async () => {
    await getAndSetAta()
    let result_account = await connection.getAccountInfo(programAccount)
    let program_idl = new BorshCoder(xplct_idl)
    let result = program_idl.accounts.decode('PAccount', result_account.data)
    if (result === null) {
      console.log('Failed to get data may be wrong account you have passed')
      return null
    }

    const tokenD = 6 // may be it require to chage as toke info.
    const solD = 9 // may be it require to chage as toke info.
    const rayD = 9 // may be it require to chage as toke info.
    const srmD = 6 // may be it require to chage as toke info.
    const udscD = 6 // may be it require to chage as toke info.

    // const tokenId = new anchor.web3.PublicKey(result.tokenId.toBuffer())

    const tokenAmount = result.tokenAmount.toNumber()
    const raise = result.raise.toNumber()
    const total = tokenAmount + raise
    const d = Math.pow(10, tokenD)

    const leftTokenAmount = parseFloat((tokenAmount / d).toString()).toFixed(2)
    const raiseAmount = parseFloat((raise / d).toString()).toFixed(2)
    const totalAmount = parseFloat((total / d).toString()).toFixed(2)

    props.setLeftToken(leftTokenAmount);
    props.setTotalToken(totalAmount);
    props.setRaisedToken(raiseAmount);

  }

  useEffect(() => {
    getDetails()
  }, []) //! need to run only one time not on every events or state change.

  return <></>
}
