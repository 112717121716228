import React, { useState } from 'react'
import '../assets/css/style.css'
import '../assets/css/vendor.bundle.css'
import '../assets/css/style-salvia.css'
import '../assets/css/theme.css'
import {
    Link
} from "react-router-dom";
// import logo from '../images/logo-full-white.png'
import logo from '../images/home/03a.png'
// import logofullwhite from '../images/logo-full-white.png'
import sc_mockup from '../images/app-screens/sc-mockup.png'
import slide_one from '../images/app-screens/sc-slide-one.jpg'
import slide_two from '../images/app-screens/sc-slide-two.jpg'
import slide_three from '../images/app-screens/sc-slide-three.jpg'
import wave from '../images/wave.png'
import Scrollspy from 'react-scrollspy'
import './nav.css'
const Navbar = () => {


    const downloadFile = (e) => {
        e.preventDefault();
        // using Java Script method to get PDF file
        fetch("./pdf/whitepaper.pdf").then((response) => {
          response.blob().then((blob) => {
            // Creating new object of PDF file
            const fileURL = window.URL.createObjectURL(blob);
            // Setting various property values
            let alink = document.createElement("a");
            alink.href = fileURL;
            alink.download = "SamplePDF.pdf";
            alink.click();
          });
        });
      };

    return (
        <header className="nk-header page-header is-transparent is-sticky is-shrink is-split has-fixed" id="header">
            <div class="header-main">
                <div class="container container-xxl">
                    <div class="header-wrap">

                        <div class="header-logo logo animated" data-animate="fadeInDown" data-delay=".65">
                            <a href="./" class="logo-link">
                                <img class="logo-dark" src={logo} srcset="images/logo2x.png 2x" alt="logo" />
                                <img class="logo-light" src={logo} srcset="images/logo-full-white2x.png 2x" alt="logo" />
                            </a>
                        </div>

                        <div class="header-nav-toggle">
                            <a href="#" class="navbar-toggle" data-menu-toggle="header-menu">
                                <div class="toggle-line">
                                    <span></span>
                                </div>
                            </a>
                        </div>

                        <div class="header-navbar header-navbar-s2 flex-grow-1 animated" data-animate="fadeInDown" data-delay=".75">
                            <Scrollspy items={['platform', 'wallet', 'metaverse', 'team']} currentClassName="is-current">
                                   <nav class="header-menu header-menu-s2" id="header-menu">
                                    <ul class="menu mx-auto">
                                        <li class="menu-item">
                                            <a class=" nav-link" href="#">Home</a>
                                        </li>


                                        <li class="menu-item has-sub">
                                            <a class="nav-link menu-toggle" href="#">Projects</a>
                                            <div class="menu-sub menu-drop">
                                                <div class="menu-mega-innr">
                                                    <ul class="menu-mega-list">
                                                        <li class="menu-item"><a href="#platform">Platform </a></li>
                                                        <li class="menu-item"><a href="#wallet"> Wallet</a></li>
                                                        <li class="menu-item"><a href="#metaverse">Metaverse </a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </li>

                                        <li class="menu-item">
                                            <a class=" nav-link" href="#token">How to Buy</a>
                                        </li>
                                        <li class="menu-item">
                                            <a class="nav-link" href="#roadmap">Roadmap</a>
                                        </li>
                                        <li class="menu-item">
                                            <a class=" nav-link" href="#team">Team</a>
                                        </li>

                                        <li class="menu-item has-sub">
                                            <a class=" nav-link menu-toggle" href="#">More</a>
                                            <div class="menu-sub menu-drop">
                                                <div class="menu-mega-innr">
                                                    <ul class="menu-mega-list">
                                                        <li class="menu-item"><a href="#partners">Partners</a></li>
                                                        {/* <li class="menu-item"><a href="#press">Press</a></li> */}
                                                        <li class="menu-item"><a href="#blog">Blog</a></li>
                                                        <li class="menu-item"><a href="#faq">FAQ</a></li>
                                                        <li class="menu-item"><a href="#contact">Contact</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </li>

                                    </ul>

                                </nav>
                            </Scrollspy>
                        </div>

                               
                        <div>
                            <ul class="menu-btns">
                                <li role="button" onClick={downloadFile}><a href="#" target="_blank" class="btn btn-rg btn-auto btn-outline  on-bg-theme btn-round btn-header"><span>Whitepaper</span></a></li>
                                <li><a href="/signin" class="btn btn-rg btn-auto btn-outline on-bg-theme btn-round  btn-header"><span>Login</span></a></li>
                            </ul>
                        </div>
           
                    </div>
                </div>
            </div>


        </header>
    )
}

export default Navbar