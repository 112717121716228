
export async function getTokenTransactionInfo(connection, signature) {
  const res = await connection.getTransaction(signature)
  const transactionData = res.meta

  let logMsgs = transactionData.logMessages
  let msg = logMsgs[logMsgs.length - 3]
  if (msg == null || !msg.includes('&?')) {
    return null
  }

  let split_msg = msg.split(' ')
  let token_amount = split_msg[3]
  let cost = split_msg[5] + ' ' + split_msg[6]

  const bought_time = new Date(res.blockTime * 1000).toLocaleDateString(
    'en-US',
    {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    },
  )

  return {
    sign: signature,
    boughtToken: token_amount,
    cost: cost,
    boughtTime: bought_time,
  }
}
export default getTokenTransactionInfo
